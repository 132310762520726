import { useEffect, useState } from "react";
import {
  AddDeviceContainer,
  ChangeStatusTable,
  CloseButton,
  LoadingOverlay,
  SelectRejectionItem,
} from "../styles";
import { ButtonCancel } from "../../../modals/ConfirmModal/styles";
import { ButtonSave } from "../../../components/ModalCompound/styles";
import {
  IBurninDetails,
  IDevices,
  InspectionStepsEnum,
  InspectionStatusEnum,
} from "../types";
import useApi from "../../../hooks/useApi";
import { MdClose } from "react-icons/md";
import { post, put } from "../../../utils/api";
import { success } from "../../../components/Toast";
import { Spinner } from "react-bootstrap";

interface IavailableSlots {
  positionId: number;
  positionName: string;
  positioAvailable: boolean;
}

interface IChangeStatus {
  inspectionId: string;
  availableSlots: IavailableSlots[];
  deviceData?: IDevices | null;
  burninDetails?: IBurninDetails | null;
  type: InspectionStepsEnum;
  closeModal: () => void;
  handleNext: (statusPayload?: {
    id: string | number;
    status: InspectionStatusEnum;
    details: IReasonsValues;
  }) => void;
}

const visualRejectionReasons = [
  { value: "case", label: "Case" },
  { value: "base", label: "Base" },
  { value: "screw", label: "Parafuso" },
  { value: "baseFixing", label: "Fixação Base" },
  { value: "sticker", label: "Adesivo" },
  { value: "QRCode", label: "QR-code" },
  { value: "deviceTurnedOn", label: "Desligado" },
  { value: "tableFitting", label: "Encaixe Mesa" },
  { value: "screwDoesNotTouchKey", label: "Parafuso não encosta na chave" },
];

const packingRejectionReasons = [
  { value: "recharged", label: "Recarregado" },
  { value: "screwOnOff", label: "Parafusos ON/OFF" },
  { value: "screwM8", label: "Parafusos M8" },
  { value: "bubbleWrap", label: "Plásticos Bolha" },
  { value: "packaging", label: "Embalagem" },
];

const burninRejectionReasons = [
  { value: "isConnected", label: "Conexão" },
  { value: "lastCollectRSSI", label: "RSSI" },
  { value: "lastCollectFFT", label: "FFT" },
  { value: "lastCollectRMS", label: "RMS" },
  { value: "performance", label: "Performance" },
  { value: "batteryVoltage", label: "Voltagem da bateria" },
  { value: "initialBatteryVoltage", label: "Voltagem inicial da bateria" },
  { value: "temperature", label: "Temperatura" },
  { value: "batteryConsumption", label: "Consumo da bateria" },
];

interface IReasonsSelected {
  [k: string]: string;
}
interface IReasonsValues {
  [k: string]: boolean;
}

export function ChangeStatus({
  inspectionId,
  deviceData,
  burninDetails,
  type,
  closeModal,
  handleNext,
  availableSlots,
}: IChangeStatus) {
  const positionName = deviceData?.positionName || burninDetails?.positionName;
  const positionId = deviceData?.positionId || burninDetails?.positionId;
  const statusDefined = deviceData?.initialVisualInspectionStatus !== "PENDING";
  const id = deviceData?.id || burninDetails?.qualityDeviceId;
  const activatorId = deviceData?.activatorId || burninDetails?.activatorId;
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [pathStatus, setPathStatus] = useState<string>("");
  const [rejectionReasons, setRejectionReasons] = useState<IReasonsSelected[]>(
    []
  );
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [selectedPosition, setSelectedPosition] = useState<{
    positionId: number;
    positionName: string;
  } | null>({
    positionId: positionId || 0,
    positionName: positionName || "",
  });

  const { request: requestDevices, processing: processingDevices } = useApi({
    path: `/infra/quality-inspections/${inspectionId}/devices`,
  });

  useEffect(() => {
    switch (type) {
      case InspectionStepsEnum.INITIAL_VISUAL:
        setRejectionReasons(visualRejectionReasons);
        setSelectedReasons(
          getCurrentReasons(deviceData?.initialVisualInspectionDetails)
        );
        setPathStatus("/initial-visual-inspection");
        break;
      case InspectionStepsEnum.BURNIN_STATUS:
        setRejectionReasons(burninRejectionReasons);
        setPathStatus("/burnin-details");
        break;
      case InspectionStepsEnum.FINAL_VISUAL:
        setRejectionReasons(visualRejectionReasons);
        setSelectedReasons(
          getCurrentReasons(deviceData?.finalVisualInspectionDetails)
        );
        setPathStatus("/final-visual-inspection");
        break;
      case InspectionStepsEnum.PACKING:
        setRejectionReasons(packingRejectionReasons);
        setSelectedReasons(
          getCurrentReasons(deviceData?.packingInspectionDetails)
        );
        setPathStatus("/packing-inspection");
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (availableSlots) {
      const positionSelected =
        availableSlots?.find((slot) => slot.positionName === positionName) ||
        availableSlots.filter((slot) => slot.positioAvailable)[0];
      setSelectedPosition(positionSelected ?? null);
    }
  }, [availableSlots, positionName]);

  const getCurrentReasons = (data: IReasonsValues | null | undefined) => {
    if (!data) return [];
    let reasons: string[] = [];
    for (let reason in data) {
      if (!data[reason]) {
        reasons.push(reason);
      }
    }
    return reasons;
  };

  const handleCheckboxChange = (value: string) => {
    setSelectedReasons((checked) =>
      checked.includes(value)
        ? checked.filter((item) => item !== value)
        : [...checked, value]
    );
  };

  const buildReasonToRejected = () => {
    let rejected: any = {};
    switch (type) {
      case InspectionStepsEnum.INITIAL_VISUAL:
      case InspectionStepsEnum.FINAL_VISUAL:
      case InspectionStepsEnum.PACKING:
        rejectionReasons.forEach((reason) => {
          const isSelected = selectedReasons.includes(reason.value);
          if (isSelected) {
            rejected[reason.value] = false;
          }
        });
        break;
      case InspectionStepsEnum.BURNIN_STATUS:
        rejectionReasons.forEach((reason) => {
          const isSelected = selectedReasons.includes(reason.value);
          const burninData: { [k: string]: string | number | boolean } = {
            ...burninDetails,
          };
          if (isSelected) {
            const currentvalue =
              burninData[reason.value] === "N/A" ? 0 : burninData[reason.value];
            rejected[reason.value] = {
              value: currentvalue,
              status: false,
            };
          }
        });
        break;

      default:
        break;
    }

    return rejected;
  };

  const handleActivateDevice = (
    qualityInspectionId: number,
    deviceId: number,
    activatorId: string
  ) => {
    post({
      path: `/infra/quality-inspections/${qualityInspectionId}/devices/${deviceId}/activate`,
      body: {
        activatorId,
      },
    }).then(() => {
      success("Ponto ativado com sucesso");
    });
  };

  const handleCreate = async ({
    isApproved,
  }: {
    isApproved: boolean;
  }): Promise<IDevices> => {
    try {
      const response: IDevices = await requestDevices({
        method: "post",
        body: {
          activatorId,
          activatePositionId: positionId,
          isApproved,
        },
      });
      return response;
    } catch (error: any) {
      handleNext(undefined);
      throw new Error(`Failed to create device: ${error.message}`);
    }
  };

  const getDeviceQualityId = async (
    currentId: number | undefined,
    isApproved: boolean
  ): Promise<number | undefined> => {
    if (type === InspectionStepsEnum.INITIAL_VISUAL && !deviceData?.createdBy) {
      const response = await handleCreate({ isApproved });
      return Number(response.id);
    }

    return currentId;
  };

  const handleDeactivateDevice = async (
    qualityInspectionId: number,
    deviceId: number
  ) => {
    await put({
      path: `/infra/quality-inspections/${qualityInspectionId}/devices/${deviceId}/deactivate`,
    });
  };

  const handleReject = async () => {
    setIsProcessing(true);
    let deviceQualityId = await getDeviceQualityId(id, false);

    const rejected = {
      id: deviceQualityId || "",
      status: InspectionStatusEnum.REJECTED,
      details: buildReasonToRejected(),
    };

    if (
      deviceData?.createdBy &&
      type === InspectionStepsEnum.INITIAL_VISUAL &&
      deviceData?.positionId
    ) {
      await handleDeactivateDevice(
        deviceData.qualityInspectionId,
        deviceQualityId || 0
      );
    }
    await requestDevices({
      method: "put",
      pathParameters: deviceQualityId + pathStatus,
      body: rejected,
    });

    if (type !== InspectionStepsEnum.INITIAL_VISUAL) closeModal();
    setIsProcessing(false);
    handleNext(rejected);
    setSelectedReasons([]);
  };

  const handleAprove = async () => {
    setIsProcessing(true);
    let deviceQualityId = await getDeviceQualityId(id, true);

    await requestDevices({
      method: "put",
      pathParameters: deviceQualityId + pathStatus,
      body: {
        positionName: selectedPosition?.positionName ?? "",
        positionId: selectedPosition?.positionId ?? 0,
        status: InspectionStatusEnum.APPROVED,
        details: {},
      },
    }).then(() => {
      setIsProcessing(false);
      if (deviceData?.createdBy && !positionName) {
        handleActivateDevice(
          Number(inspectionId),
          deviceData?.id ?? 0,
          activatorId ?? ""
        );
      }
    });
    if (type !== InspectionStepsEnum.INITIAL_VISUAL) closeModal();
    handleNext({
      id: deviceQualityId || "",
      status: InspectionStatusEnum.APPROVED,
      details: {},
    });
  };

  const renderSensorInfo = (uuid: string = "", order: string = "") => {
    return (
      <ChangeStatusTable>
        <thead>
          <th>UUID</th>
          <th>Posição</th>
        </thead>
        <tbody>
          <tr>
            <td>{uuid}</td>
            {deviceData?.createdBy && !positionName ? (
              <td>
                <select
                  value={selectedPosition?.positionId ?? ""}
                  onChange={(e) =>
                    setSelectedPosition(
                      (availableSlots || []).find(
                        (slot) => slot.positionId === Number(e.target.value)
                      ) ?? null
                    )
                  }
                >
                  {availableSlots &&
                    availableSlots.map((slot) => (
                      <option
                        className="select-slots"
                        key={slot.positionId}
                        value={slot.positionId}
                        disabled={!slot.positioAvailable}
                      >
                        {slot.positionName}
                      </option>
                    ))}
                </select>
              </td>
            ) : (
              <td>{order}</td>
            )}
          </tr>
        </tbody>
      </ChangeStatusTable>
    );
  };

  return (
    <AddDeviceContainer changeStatus>
      {processingDevices && (
        <LoadingOverlay>
          <Spinner
            style={{
              height: "32px",
              width: "32px",
              marginLeft: "8px",
              marginBottom: "-4px",
            }}
            animation="border"
          />
        </LoadingOverlay>
      )}
      <header>
        {statusDefined ? (
          <CloseButton onClick={closeModal}>
            <MdClose />
          </CloseButton>
        ) : (
          <></>
        )}

        <span>Análise Visual do Sensor</span>

        {renderSensorInfo(activatorId, positionName)}
      </header>
      <section>
        <span>Selecionar inconformidade:</span>

        <div className="rejectionContainer">
          {rejectionReasons.map((reason, index) => (
            <SelectRejectionItem
              key={reason.value}
              selected={selectedReasons.includes(reason.value)}
            >
              <input
                type="checkbox"
                id={`reason-${index}`}
                name="rejectionReason"
                value={reason.value}
                checked={selectedReasons.includes(reason.value)}
                onChange={() => handleCheckboxChange(reason.value)}
              />
              <label htmlFor={`reason-${index}`}>{reason.label}</label>
            </SelectRejectionItem>
          ))}
        </div>
      </section>
      <footer>
        <ButtonCancel
          className="rejectionButton"
          onClick={handleReject}
          disabled={selectedReasons.length === 0 || isProcessing}
        >
          Reprovado
        </ButtonCancel>
        <ButtonSave
          className="saveButton"
          onClick={handleAprove}
          disabled={
            selectedReasons.length !== 0 ||
            isProcessing ||
            !selectedPosition?.positionId
          }
        >
          Aprovado
        </ButtonSave>
      </footer>
    </AddDeviceContainer>
  );
}
