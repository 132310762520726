import { BuildRoutes } from "../../routes/BuildRoutes";

import { UserResource } from "./resources/Users";
import { UsersFacilitiesResource } from "./resources/UsersFacilities";
import { PositionsResource } from "./resources/positions";
import { AnnouncementsResource } from "./resources/announcements";
import { occurrenceExclusionReasonsResource } from "./resources/occurrenceExclusionReasons";
import { AssetsResource } from "./resources/assets";
import { CompaniesResource } from "./resources/companies";

const resourcesRoutes = [
  UserResource,
  UsersFacilitiesResource,
  PositionsResource,
  AnnouncementsResource,
  occurrenceExclusionReasonsResource,
  AssetsResource,
  CompaniesResource,
]
  .map((resource) => BuildRoutes(resource))
  .reduce((acc, curr) => {
    return [...acc, ...curr];
  }, []);

export const WEB_ROUTES = [...resourcesRoutes];
