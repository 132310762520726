import { AnchorListContainer, DivListContainer } from "../../styles";

type RootProps = Omit<Partial<HTMLAnchorElement>, "children"> &
  Omit<Partial<HTMLButtonElement>, "children"> & {
    children: any;
  };

export function Root({ children, href, target }: RootProps) {
  if (href) {
    return <AnchorListContainer href={href} target={target}>{children}</AnchorListContainer>;
  }
  return <DivListContainer>{children}</DivListContainer>;
}
