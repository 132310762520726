export function readCSVFile(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event: any) {
      const csvContent = event.target.result;
      const lines = csvContent.split("\n");

      const headers = lines[0].split(";");

      const result = [];

      for (let i = 1; i < lines.length; i++) {
        const line = lines[i].trim();
        if (line) {
          const values = line.split(";");
          const obj: any = {};
          headers.forEach((header: any, index: any) => {
            obj[header.trim()] = values[index]?.trim();
          });
          if (obj?.positionId && obj?.positionName && obj?.activatorId) {
            result.push(obj);
          }
        }
      }
      resolve(result);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsText(file);
  });
}

export const clearFile = ({
  acceptedFiles,
  csvRef,
}: {
  acceptedFiles: any;
  csvRef: any;
}) => {
  acceptedFiles.pop();
  csvRef.current.value = "";
};

const validations = [
  ({ data }: any) => {
    if (!Array.isArray(data)) {
      return {
        isValid: false,
        message:
          "Não conseguimos ler o arquivo. Por favor, verifique se ele está no formato correto e tente novamente.",
      };
    }
  },
  ({ data }: any) => {
    if (data.length === 0) {
      return {
        isValid: false,
        message: "Arquivo vazio",
      };
    }
  },
  ({ data, burninSize }: any) => {
    if (burninSize < data.length) {
      return {
        isValid: false,
        message:
          "A quantidade de pontos ultrapassou o limite permitido para o burn-in.",
      };
    }
  },
  ({ data }: any) => {
    const headers = Object.keys(data[0]);

    if (
      !headers[0]?.includes("positionId") ||
      !headers[1]?.includes("positionName") ||
      !headers[2]?.includes("activatorId")
    ) {
      return {
        isValid: false,
        message:
          "Não foi possível validar o header. Verifique se o arquivo CSV segue o padrão esperado e tente novamente.",
      };
    }
  },
  ({ data }: any) => {
    const emptyIndex = (data as any[])?.findIndex(
      (device) =>
        !device?.activatorId || !device?.positionId || !device?.positionName
    );
    if (emptyIndex > -1) {
      return {
        isValid: false,
        message: `O arquivo contém elementos vazios na linha ${
          emptyIndex + 2
        }. Por favor, verifique e corrija os dados.`, //index + 1 para adicionar o header e +1 para começar a contagem do 1
      };
    }
  },
];

type ValidationResult = {
  isValid: boolean;
  message: string;
};

export const fileValidation = async ({
  acceptedFiles,
  burninSize,
}: any): Promise<ValidationResult> => {
  const data = await readCSVFile(acceptedFiles[0]);
  let validated = { isValid: true, message: "" };

  validations.find((validationFn) => {
    const result: any = validationFn({ data, burninSize });
    if (result !== undefined) {
      validated = result;
      return true;
    }
    return false;
  }) as any;

  return validated;
};
