import { ListActive } from "../../../components/List/ListActive";
import { ListProfile } from "../../../components/List/ListProfile";
import { ListDate } from "../../../components/List/ListDate";
import { ShowActive } from "../../../components/Show/ShowActive";
import { ShowDate } from "../../../components/Show/ShowDate";
import { ShowProfile } from "../../../components/Show/ShowProfile";

import { put } from "../../../utils/api";
import { ListUserContact } from "../../../components/List/ListUserContact";
import { ListUserCompany } from "../../../components/List/ListUserCompany";

import { NewCustom } from "../pages/Users/NewCustom";
import { EditCustom } from "../pages/Users/EditCustom";
import { PROFILES } from "../../../store/slices/userSlice";
import { ExportUserCSV } from "./exportUserCSV/exportUserCSV";

export const UserResource = {
  title: "Usuários Retina",
  urlPath: "/web/users",
  apiPath: "/web/users",
  actions: {
    new: {
      isVisible: false,
      page: ({ resource }: any) => <NewCustom resource={resource} />,
    },
    edit: {
      isVisible: true,
      page: ({ resource }: any) => <EditCustom resource={resource} />,
    },
    resetPassword: {
      type: "record",
      label: "Resetar senha",
      guard: "Tem certeza que deseja resetar a senha?",
      handler: async (record: any) =>
        put({
          path: `/web/users/${record.cognitoId}/reset-password`,
        }),
    },
    activeInactive: {
      type: "record",
      label: "Ativar/desativar",
      guard: "Tem certeza que deseja ativar/desativar usuário?",
      handler: async (record: any) => {
        put({
          path: `/web/users/${record.cognitoId}/active-inactive`,
        });
      },
    },
    exportCSV: {
      type: "resource",
      name: "exportCSV",
      label: "Exportar CSV",
      modalComponent: () => <ExportUserCSV />,
    },
  },
  properties: {
    cognitoId: {
      id: "cognitoId",
      type: "TEXT",
      label: "ID",
      isPrimaryKey: true,
      isVisible: {
        show: true,
        filter: true,
        list: true
      },
    },
    userInfo: {
      id: "userInfo",
      type: "TEXT",
      label: "CONTATO",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: any) => (
          <ListUserContact record={record} id={id} />
        ),
      },
    },
    email: {
      id: "email",
      type: "TEXT",
      label: "EMAIL",
      isVisible: {
        filter: true,
        show: true,
        new: true,
        edit: true,
      },
    },
    phone: {
      id: "phone",
      type: "TEXT",
      label: "TELEFONE",
      isVisible: {
        filter: true,
        show: true,
        new: true,
        edit: true,
      },
    },
    name: {
      id: "name",
      type: "TEXT",
      label: "NOME",
      isVisible: {
        filter: true,
        show: true,
        new: true,
        edit: true,
      },
    },
    profile: {
      id: "profile",
      type: "SELECT",
      options: [
        {
          label: "Executivo Master",
          value: PROFILES.EXECUTIVO_MASTER,
        },
        {
          label: "Mesa Master",
          value: PROFILES.MESA_MASTER,
        },
        {
          label: "Representante",
          value: PROFILES.REPRESENTANTE,
        },
        {
          label: "Mesa Analista",
          value: PROFILES.MESA_ANALISTA,
        },
        {
          label: "Cliente Master",
          value: PROFILES.CLIENTE_MASTER,
        },
        {
          label: "Cliente Comum",
          value: PROFILES.CLIENTE_COMUN,
        },
      ],
      label: "PERFIL",
      component: {
        list: ({ record, id }: any) => <ListProfile record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowProfile record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
        show: true,
        filter: true,
        edit: true,
      },
    },
    companyDetails: {
      id: "companyDetails",
      type: "TEXT",
      label: "EMPRESA/UNIDADE/INTEGRADOR",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: any) => (
          <ListUserCompany record={record} id={id} />
        ),
      },
    },
    companyId: {
      id: "companyId",
      label: "EMPRESA",
      type: "SELECT_API",
      endpoint: "/companies-options",
      isVisible: {
        filter: true,
        edit: true,
      },
    },
    companyName: {
      id: "companyName",
      label: "EMPRESA",
      type: "TEXT",
      isVisible: {
        show: true,
      },
    },
    facilityId: {
      id: "facilityId",
      label: "UNIDADE",
      type: "SELECT_API",
      endpoint: "/facilities-options",
      isVisible: {
        filter: true,
        edit: true,
      },
    },
    facilityName: {
      id: "facilityName",
      label: "UNIDADE",
      type: "TEXT",
      isVisible: {
        show: true,
      },
    },
    createDate: {
      id: "createdAt",
      type: "TEXT",
      label: "CRIADO EM",
      component: {
        list: ({ record, id }: any) => <ListDate record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowDate record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
        show: true,
      },
    },
    lastAccessDate: {
      id: "lastAccessDate",
      type: "TEXT",
      label: "ULTIMO ACESSO",
      component: {
        list: ({ record, id }: any) => <ListDate record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowDate record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
        show: true,
      },
    },
    modifiedDate: {
      id: "modifiedDate",
      type: "TEXT",
      label: "MODIFICADO EM",
      component: {
        list: ({ record, id }: any) => <ListDate record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowDate record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        show: true,
      },
    },
    status: {
      id: "status",
      type: "SELECT",
      label: "ATIVO?",
      options: [
        {
          label: "Ativo",
          value: true,
        },
        {
          label: "Inativo",
          value: false,
        },
      ],
      component: {
        list: ({ record, id }: any) => <ListActive record={record} id={id} />,
        show: ({ record, label, id }: any) => (
          <ShowActive record={record} label={label} id={id} />
        ),
      },
      isVisible: {
        list: true,
        show: true,
        filter: true,
      },
    },
  },
};
