import styled, { css } from "styled-components";
import { StatusEnum } from "./model";

export const Container = styled.div`
  display: flex;
  padding: 0.3rem;
  flex-direction: column;
  gap: 8px;
  height: 500px;
`;

export const Title = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  margin-top: 0.5rem;
  align-items: center;
  ${(props) => ({ ...props.theme.fonts.primary.H3SemiBold })};
  margin-bottom: 10px;
`;

export const CloseButton = styled.button`
  position: absolute;
  border: none;
  background: none;
  right: 0;
  top: -50%;
  cursor: pointer;

  svg {
    color: ${(props) => props.theme.colors.iconsPrimary};

    &:hover {
      color: ${(props) => props.theme.colors.statusRisk};
    }
  }
`;

export const WrapperBadge = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const Badge = styled.span<{
  variant: "PROCESSED" | "IN_PROGRESS" | "NOT_STARTED";
  isVisible: boolean;
}>`
  width: fit-content;
  border-radius: 0.3rem;
  padding: 0.25rem;
  font-size: 0.75rem;
  border: 1px solid ${(props) => props.theme.colors.divBackground};
  display: flex;
  gap: 6px;

  ${({ isVisible }) =>
    isVisible &&
    css`
      display: none;
    `};

  ${({ variant }) =>
    variant === "PROCESSED" &&
    css`
      background-color: rgb(204, 250, 230);
      border-color: rgb(20, 151, 107);
      color: rgb(20, 151, 107);
    `};
  ${({ variant }) =>
    variant === "IN_PROGRESS" &&
    css`
      background-color: rgb(222, 234, 248);
      border-color: rgb(70, 142, 224);
      color: rgb(70, 142, 224);
    `};
  ${({ variant }) =>
    variant === "NOT_STARTED" &&
    css`
      background-color: rgb(188, 190, 192);
      border-color: rgb(70, 72, 75);
      color: rgb(70, 72, 75);
    `};
`;

export const Header = styled.head`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.ibbxOrange};
  color: ${({ theme }) => theme.colors.iconsPrimary};
  padding: 0.3rem 0.5rem;
  border-radius: 6px;
  transition: all 0.3s ease;
  ${({ theme }) => theme.fonts.primary.H4Bold};

  :hover {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
  }

  :disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: none;
  }
`;

export const Footer = styled.footer`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const List = styled.ul`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;
  font-size: 0.875rem;
  gap: 0.5rem;
  height: 100%;
  overflow-y: auto;
  padding-right: 0.5rem;

  &::-webkit-scrollbar {
    width: 7px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background} !important;
    width: 20px !important;
    border-radius: 100vw !important;
    margin-block: -4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.iconsSecondaryAlpha} !important;
    height: 100% !important;
    max-height: 5rem !important;
    border-radius: 100vw !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.iconsSecondary} !important;
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${({ theme }) => theme.colors.iconsSecondary} !important;
  }
`;

export const ListItem = styled.li<{
  variant: StatusEnum;
}>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 80px 100px;
  border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
  border-radius: 5px;
  padding: 0.5rem 0.5rem 0.5rem 0.6rem;
  position: relative;
  gap: 0.25rem;

  &::before {
    content: "";
    border-radius: 5px 0 0 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    height: 100%;
    width: 5px;
    opacity: 0.8;

    ${({ variant }) =>
      variant === "PROCESSED" &&
      css`
        background-color: rgb(20, 151, 107);
      `};
    ${({ variant }) =>
      variant === "PROCESSING" &&
      css`
        background-color: rgb(70, 142, 224);
      `};
    ${({ variant }) =>
      variant === "NOT_STARTED" &&
      css`
        background-color: rgb(70, 72, 75);
      `};
  }
`;

export const ListCell = styled.span`
  display: flex;
  align-items: center;
`;

export const ListHeader = styled.div`
  padding: 0 0.5rem 0 0.6rem;
  position: sticky;
  top: auto;
  left: 0;
  display: grid;
  gap: 0.25rem;
  grid-template-columns: 1fr 1fr 1fr 80px 100px;
  background: ${(props) => props.theme.backgroundColors.background2};
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const EmptyData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 8px;
`;
