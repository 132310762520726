import { createContext, useContext } from "react";
import { IBatchActivationContext, StepsBatchActivationEnum } from "./model";

const DEFAULT: IBatchActivationContext = {
  closeModal: () => {},
  setStep: () => {},
  burninSize: 0,
  devicesQuantity: 0,
  step: StepsBatchActivationEnum.UPLOAD_FILE,
  companyId: undefined,
  batchErrors: [],
  setBatchErrors: () => {},
};

const BatchActivationContext = createContext<IBatchActivationContext>(DEFAULT);

export const useBatchActivation = () => {
  const context = useContext(BatchActivationContext);
  if (context === null) {
    throw new Error(
      "BatchActivationContext deve ser usado dentro do modal batch activation"
    );
  }
  return context;
};

export default BatchActivationContext;
