import { MdDoneAll } from "react-icons/md";
import { useBatchActivation } from "./context";
import { Button, Title, SucessMensage } from "./style";

export function ConfirmedBatch() {
  const { closeModal } = useBatchActivation();
  return (
    <>
      <Title draggable="true">Ativação em massa de dispositivos</Title>
      <SucessMensage>
        <MdDoneAll size={112} color="green" />
        <p>Ativação em massa realizada com sucesso !</p>
      </SucessMensage>
      <Button onClick={closeModal}>Fechar</Button>
    </>
  );
}
