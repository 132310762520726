import { useSearchParams } from "react-router-dom";
import { Button } from "../../../components";
import { ListBoolean } from "../../../components/List/ListBoolean";
import { RecordProps } from "../../../types/default";
import { CustomCSVExport } from "../../../components/CustomCSVExport";

const defaultDateOptions = {
  dateStyle: "short",
  timeStyle: "short",
};
function dateFormat(date: any, options?: any) {
  if (!isNaN(date)) {
    date = +date;
  }

  if (date) {
    try {
      return new Intl.DateTimeFormat(
        "pt-BR",
        options || defaultDateOptions
      ).format(new Date(date));
    } catch (err) {
      return date;
    }
  }

  return "";
}

export const Endpoints = {
  title: "Endpoints",
  urlPath: "/summary/endpoints",
  apiPath: "/summary/endpoints",
  tabelao: "ENDPOINTS",
  actions: {
    new: { isVisible: false },
    show: { isVisible: false },
    edit: { isVisible: false },
    delete: { isVisible: false },
    csv: { isVisible: true, limit: 100 },
    viewHistory: {
      name: "history",
      type: "record",
      label: "Histórico",
      component: (record: any) => <></>,
    },
    currentDate: {
      name: "current",
      label: "Atual",
      type: "resource",
      Component: () => {
        const [searchParams, setQueryString] = useSearchParams();
        const minDate = new Date().getTime() - 1000 * 60 * 60 * 2;
        const hasParam = !!searchParams.get("date");
        const variant = hasParam ? "primary" : "";

        return (
          <Button
            variant={variant}
            onClick={() => {
              if (hasParam) {
                searchParams.delete("date");
              } else {
                searchParams.append("date", `GT${minDate}`);
              }
              setQueryString(searchParams);
            }}
          >
            Atual
          </Button>
        );
      },
    },
    csvTemperature: {
      name: "current",
      label: "Ranges Temperatura",
      type: "resource",
      Component: () => {
        return (
          <CustomCSVExport
            label="Ranges Temperatura"
            config={{
              apiPath: `/summary/endpoints`,
              filename: "Ranges Temeperatura",
              limit: 100,
              queryParams: {
                // date: `GT${minDate}`,
                mode: "TEMPERATURE_RANGES",
              },
            }}
            properties={{
              boardId: {
                id: "boardId",
                label: "UUID",
                type: "TEXT",
              },
              positionId: {
                id: "positionId",
                type: "NUMBER",
                label: "ID do Ponto",
              },
              positionName: {
                id: "positionName",
                label: "Nome do Ponto",
                type: "TEXT",
              },
              lessThanMinus40: {
                id: "temperatureRanges.lessThanMinus40",
                label: "< -40",
                type: "NUMBER",
              },
              lessThanMinus20: {
                id: "temperatureRanges.lessThanMinus20",
                label: "< -20",
                type: "NUMBER",
              },
              lessThanMinus0: {
                id: "temperatureRanges.lessThanMinus0",
                label: "< 0",
                type: "NUMBER",
              },
              lessThan40: {
                id: "temperatureRanges.lessThan40",
                label: "< 40",
                type: "NUMBER",
              },
              lessThan50: {
                id: "temperatureRanges.lessThan50",
                label: "< 50",
                type: "NUMBER",
              },
              lessThan60: {
                id: "temperatureRanges.lessThan60",
                label: "< 60",
                type: "NUMBER",
              },
              lessThan70: {
                id: "temperatureRanges.lessThan70",
                label: "< 70",
                type: "NUMBER",
              },
              lessThan80: {
                id: "temperatureRanges.lessThan80",
                label: "< 80",
                type: "NUMBER",
              },
              lessThan90: {
                id: "temperatureRanges.lessThan90",
                label: "< 90",
                type: "NUMBER",
              },
              lessThan100: {
                id: "temperatureRanges.lessThan80",
                label: "< 100",
                type: "NUMBER",
              },
              greaterThan100: {
                id: "temperatureRanges.greaterThan100",
                label: ">= 100",
                type: "NUMBER",
              },
            }}
          />
        );
      },
    },
    csvAcceleration: {
      name: "current",
      label: "Ranges Aceleração",
      type: "resource",
      Component: () => {
        return (
          <CustomCSVExport
            label="Ranges Aceleração"
            config={{
              apiPath: `/summary/endpoints`,
              filename: "Ranges Aceleração",
              limit: 100,
              queryParams: {
                // date: `GT${minDate}`,
                mode: "ACCELERATION_RANGES",
              },
            }}
            properties={{
              boardId: {
                id: "boardId",
                label: "UUID",
                type: "TEXT",
              },
              positionId: {
                id: "positionId",
                type: "NUMBER",
                label: "ID do Ponto",
              },
              positionName: {
                id: "positionName",
                label: "Nome do Ponto",
                type: "TEXT",
              },
              lessThan1: {
                id: "accelerationRanges.lessThan1",
                label: "< 1",
                type: "NUMBER",
              },
              lessThan2: {
                id: "accelerationRanges.lessThan2",
                label: "< 2",
                type: "NUMBER",
              },
              lessThan4: {
                id: "accelerationRanges.lessThan4",
                label: "< 4",
                type: "NUMBER",
              },
              lessThan8: {
                id: "accelerationRanges.lessThan8",
                label: "< 8",
                type: "NUMBER",
              },
              lessThan16: {
                id: "accelerationRanges.lessThan16",
                label: "< 16",
                type: "NUMBER",
              },
              lessThan32: {
                id: "accelerationRanges.lessThan32",
                label: "< 32",
                type: "NUMBER",
              },
              lessThan64: {
                id: "accelerationRanges.lessThan64",
                label: "< 64",
                type: "NUMBER",
              },
              greaterThan64: {
                id: "accelerationRanges.greaterThan64",
                label: ">= 64",
                type: "NUMBER",
              },
            }}
          />
        );
      },
    },
  },
  properties: {
    boardId: {
      id: "boardId",
      type: "TEXT",
      label: "UUID",
      isPrimaryKey: true,
      isVisible: {
        filter: true,
      },
    },
    lastUpdate: {
      id: "date",
      type: "DATE_PERIOD",
      label: "Ulitma Atualização",
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    isConnected: {
      id: "isConnected",
      type: "SELECT",
      label: "Staus Conexão",
      component: {
        list: ({ record }: any) => (
          <ListBoolean
            record={record}
            id={"isGatewayConnected"}
            true="Conectado"
            false="Desconectado"
          />
        ),
      },
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: true, label: "Conectado" },
        { value: false, label: "Desconectado" },
      ],
    },
    uptime: {
      id: "uptime",
      type: "RANGE",
      label: "Tempo Ligado",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => {
        if (record[id]) {
          const days = Math.floor(record[id] / 86400);
          const hours = Math.floor((record[id] % 86400) / 3600);
          const minutes = Math.floor((record[id] % 3600) / 60);
          return `${days}d ${hours}h ${minutes}m`;
        }
        return "";
      },
    },
    performance: {
      id: "performance",
      type: "RANGE",
      label: "Performance",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && `${record[id]}%`,
    },
    connectivityPercent: {
      id: "connectivityPercent",
      type: "RANGE",
      label: "Conexão Gateway",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && `${record[id]}%`,
    },
    lastAcquisitionDate: {
      id: "lastAcquisitionDate",
      type: "DATE_PERIOD",
      label: "Ultima Conexão",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    id: {
      id: "boardId",
      type: "TEXT",
      label: "ID",
      isVisible: {
        list: true,
      },
      openLink: ({ record, id }: any) =>
        record["boardId"]
          ? `${process.env.REACT_APP_RETINA_INFRA_URL}/monitorator/collects?activatorId=${record["boardId"]}`
          : null,
    },
    partnumber: {
      id: "partnumber",
      type: "SELECT",
      label: "Partnumber",
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: "spectrapic24915v1", label: "spectrapic24915v1" },
        { value: "connectpic24915v1 ", label: "connectpic24915v1" },
        { value: "connectcube915v1", label: "connectcube915v1" },
        { value: "spectramensor", label: "spectramensor" },
      ],
    },
    firmware: {
      id: "firmware",
      type: "TEXT",
      label: "Firmware",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    hardwareVersion: {
      id: "hardwareVersion",
      type: "SELECT",
      label: "Versão",
      isVisible: {
        list: true,
        filter: true,
      },
      options: [{ value: "1.3", label: "Versão 1.3" }],
    },
    isRetrofit: {
      id: "isRetrofit",
      type: "SELECT",
      label: "É Retrofit?",
      component: {
        list: ({ record, id }: any) => (
          <ListBoolean record={record} id={id} true="Sim" false="Não" />
        ),
      },
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: true, label: "Sim" },
        { value: false, label: "Não" },
      ],
    },
    manufacturer: {
      id: "manufacturer",
      type: "SELECT",
      label: "Fabricante",
      isVisible: {
        list: true,
        filter: true,
      },
      options: [{ value: "Etronic", label: "Etronic" }],
    },
    batch: {
      id: "batch",
      type: "TEXT",
      label: "Lote",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    companyId: {
      id: "companyId",
      type: "SELECT_HIEARCHY",
      label: "Empresa",
      endpoint: "/companies-options",
      resetOnChange: ["facilityId", "assetId", "positionId"],
      isVisible: {
        list: false,
        filter: true,
      },
    },
    companyName: {
      id: "companyName",
      label: "Empresa",
      width: "200px",
      isVisible: {
        list: true,
        filter: false,
      },
      openLink: ({ record, id }: any) =>
        record["companyId"]
          ? `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${record["companyId"]}/facilities`
          : null,
    },
    facilityId: {
      id: "facilityId",
      type: "SELECT_HIEARCHY",
      label: "Unidade",
      endpoint: "/facilities-options",
      parent: "companyId",
      resetOnChange: ["assetId", "positionId"],
      isVisible: {
        list: false,
        filter: true,
      },
    },
    facilityName: {
      id: "facilityName",
      label: "Unidade",
      width: "200px",
      isVisible: {
        list: true,
        filter: false,
      },
      openLink: ({ record, id }: any) =>
        record["companyId"] && record["facilityId"]
          ? `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${record["companyId"]}/facilities/${record["facilityId"]}/assets`
          : null,
    },
    sector: {
      id: "sector",
      label: "Setor",
      widtn: "200px",
      isVisible: {
        list: true,
        filter: false,
      },
    },
    assetId: {
      id: "assetId",
      type: "SELECT_HIEARCHY",
      label: "Ativo",
      endpoint: "/assets-options",
      parent: "facilityId",
      resetOnChange: ["positionId"],
      isVisible: {
        list: false,
        filter: true,
      },
    },
    assetName: {
      id: "assetName",
      label: "Ativo",
      widtn: "200px",
      isVisible: {
        list: true,
        filter: false,
      },
      openLink: ({ record, id }: any) =>
        record["companyId"] && record["facilityId"] && record["assetId"]
          ? `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${record["companyId"]}/facilities/${record["facilityId"]}/assets/${record["assetId"]}`
          : null,
    },
    positionName: {
      id: "positionName",
      label: "Ponto",
      widtn: "200px",
      isVisible: {
        list: true,
        filter: false,
      },
      openLink: ({ record, id }: any) =>
        record[id]
          ? `${process.env.REACT_APP_RETINA_WEB_URL}/positions/${record["positionId"]}`
          : null,
    },
    positionId: {
      id: "positionId",
      type: "SELECT_HIEARCHY",
      label: "ID do Ponto",
      endpoint: "/positions-options",
      parent: "assetId",
      resetOnChange: [],
      isVisible: {
        list: false,
        filter: true,
      },
    },
    positionIdText: {
      id: "positionIdText",
      type: "TEXT",
      label: "ID do Ponto",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    sysSensorTypeName: {
      id: "sysSensorTypeName",
      type: "SELECT",
      label: "Monitoramento",
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: "Vibração e Temperatura", label: "Vibração e Temperatura" },
        { value: "Energia Elétrica", label: "Energia Elétrica" },
        { value: "Pressão", label: "Pressão" },
        { value: "Modbus", label: "Modbus" },
        { value: "Analógico Modular", label: "Analógico Modular" },
      ],
    },
    spectralWindow: {
      id: "spectralWindow",
      label: "Janela(Hz)",
      isVisible: {
        list: true,
        filter: false,
      },
      component: {
        list: ({ record, id }: any) => (
          <span style={{ color: "white" }}>
            {record?.sysSensorTypeId === 1
              ? record.spectralWindow || "6400"
              : "-"}
          </span>
        ),
      },
    },
    scheduleSynchronized: {
      id: "scheduleSynchronized",
      type: "SELECT",
      label: "Status Sincronização",
      component: {
        list: ({ record, id }: any) => (
          <ListBoolean
            record={record}
            id={id}
            true="Sincronizado"
            false="Desincronizado"
          />
        ),
      },
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: true, label: "Sincronizado" },
        { value: false, label: "Desincronizado" },
      ],
    },
    isActivated: {
      id: "isActivated",
      type: "SELECT",
      label: "Status Ativação",
      component: {
        list: ({ record, id }: any) => (
          <ListBoolean
            record={record}
            id={id}
            true="Ativado"
            false="Desativado"
          />
        ),
      },
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: true, label: "Ativado" },
        { value: false, label: "Desativado" },
      ],
    },
    activatedAt: {
      id: "activatedAt",
      type: "DATE_PERIOD",
      label: "Ultima Ativação",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    daysActivated: {
      id: "daysActivated",
      type: "RANGE",
      label: "Dias Ativado",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) => {
        if (!record["isActivated"] || !record["activatedAt"]) {
          return "";
        }

        const activatedDate = new Date(record["activatedAt"]);
        const currentDate = new Date();

        const activatedDateNoTime = new Date(
          activatedDate.getFullYear(),
          activatedDate.getMonth(),
          activatedDate.getDate()
        );

        const currentDateNoTime = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );

        const diffTime =
          currentDateNoTime.getTime() - activatedDateNoTime.getTime();
        const diffDays = Math.floor(diffTime / 86400000);

        return diffDays;
      },
    },
    daysConnected: {
      id: "daysConnected",
      type: "RANGE",
      label: "Dias Conectado",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    gatewayId: {
      id: "gatewayId",
      type: "TEXT",
      label: "Gateway ID",
      isVisible: {
        list: true,
        filter: true,
      },
      openLink: ({ record, id }: any) =>
        record[id]
          ? `${process.env.REACT_APP_RETINA_INFRA_URL}/summary/gateways/${record[id]}/history`
          : null,
    },
    gatewayName: {
      id: "gatewayName",
      type: "TEXT",
      label: "Gateway Name",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    gatewayConnected: {
      id: "isGatewayConnected",
      type: "TEXT",
      label: "Gateway Status",
      isVisible: {
        list: true,
        filter: true,
      },
      component: {
        list: ({ record }: any) =>
          record.gatewayId && (
            <ListBoolean
              record={record}
              id={"isGatewayConnected"}
              true="Online"
              false="Offline"
            />
          ),
      },
    },
    schedulePosition: {
      id: "schedulePosition",
      type: "RANGE",
      label: "Slot do Agendamento",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    acquisitionInterval: {
      id: "acquisitionInterval",
      type: "RANGE",
      label: "Intervalo Coletas(min)",
      isVisible: {
        list: true,
        filter: false,
      },
    },
    globalTransmissionInterval: {
      id: "globalTransmissionInterval",
      type: "RANGE",
      label: "Intervalo Globais",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && `${record[id].toFixed(2)} min`,
    },
    dynamicTransmissionInterval: {
      id: "dynamicTransmissionInterval",
      type: "RANGE",
      label: "Intervalo Dinâmicas",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && `${record[id].toFixed(2)} min`,
    },
    scheduleRssi: {
      id: "scheduleRssi",
      type: "RANGE",
      label: "Potência sinal Sincronizaçao",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    lastCollectRSSI: {
      id: "lastCollectRSSI",
      type: "RANGE",
      label: "Potência sinal Transmissão",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    signalQuality: {
      id: "signalQuality",
      type: "RANGE",
      label: "Qualidade do Sinal",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    nextGlobalAt: {
      id: "nextGlobalAt",
      type: "TEXT",
      label: "Próxima Global",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        dateFormat(record[id], { timeStyle: "medium" }),
    },
    nextDynamicAt: {
      id: "nextDynamicAt",
      type: "TEXT",
      label: "Próxima Dinâmica",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        dateFormat(record[id], { timeStyle: "short" }),
    },
    nextConfigAt: {
      id: "nextConfigAt",
      type: "TEXT",
      label: "Próxima Configuração",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        dateFormat(record[id], { timeStyle: "short" }),
    },
    initialBatteryVoltage: {
      id: "initialBatteryVoltage",
      type: "RANGE",
      label: "Tensão Inicial bateria(V)",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && record[id].toFixed(2),
    },
    batteryVoltage: {
      id: "batteryVoltage",
      type: "RANGE",
      label: "Tensão Atual da bateria(V)",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && record[id].toFixed(2),
    },
    batteryPercent: {
      id: "batteryPercent",
      type: "RANGE",
      label: "Carga Atual Bateria(%)",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && record[id].toFixed(2),
    },
    batteryConsumption: {
      id: "batteryConsumption",
      type: "RANGE",
      label: "Consumo médio (mAh)",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        (record[id] || "") && record[id].toFixed(2),
    },
    temperature: {
      id: "temperature",
      type: "RANGE",
      label: "Temperatura (C)",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    operationFrequency: {
      id: "operationFrequency",
      type: "TEXT",
      label: "Frequência de Tx(MHz)",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    batteryLifeForecast: {
      id: "batteryLifeForecast",
      type: "DATE_PERIOD",
      label: "Previsão de vida da bateria",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    recallForecast: {
      id: "recallForecast",
      type: "DATE_PERIOD",
      label: "Recall programado",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    lastRecallAt: {
      id: "lastRecallAt",
      type: "DATE_PERIOD",
      label: "Ultimo Recall realizado",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    isRecall: {
      id: "isRecall",
      type: "SELECT",
      label: "Recall realizado",
      formatedValue: ({ record, id }: RecordProps) => (record[id] ? "Sim" : ""),
      isVisible: {
        list: false,
        filter: false,
      },
      options: [
        { value: false, label: "Sim" },
        { value: false, label: "Não" },
      ],
    },
    recallReason: {
      id: "recallReason",
      type: "TEXT",
      label: "Motivo Recall",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    totalGatewaysListen: {
      id: "totalGatewaysListen",
      type: "TEXT",
      label: "Total gateways escutando",
      isVisible: {
        list: false,
      },
    },
    availableGatewaysListen: {
      id: "availableGatewaysListen",
      type: "TEXT",
      label: "Gateways sinal bom",
      isVisible: {
        list: false,
      },
    },
    unavailableGatewaysListen: {
      id: "unavailableGatewaysListen",
      type: "TEXT",
      label: "Gateways sinal ruim",
      isVisible: {
        list: false,
      },
    },
  },
};
