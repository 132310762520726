import { PageProps, RecordProps } from "../../../types/default";
import { ListBoolean } from "../../../components/List/ListBoolean";
import { BoltChangeUpdatingMode } from "../../configurator/pages/BoltChangeUpdatingMode";
import { ModalOperationFrequency } from "../components/ModalOperationFrequency";
import { ModalGatewayWindow } from "../components/ModaGatewayWindow";

export function dateFormat(date: any) {
  if (!isNaN(date)) {
    date = +date;
  }

  if (date) {
    return new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
      timeStyle: "short",
    }).format(new Date(date));
  }

  return "";
}

export const Gateways = {
  title: "Gateways",
  urlPath: "/summary/gateways",
  apiPath: "/summary/gateways",
  actions: {
    new: { isVisible: false },
    show: { isVisible: false },
    edit: { isVisible: false },
    delete: { isVisible: false },
    viewHistory: {
      name: "history",
      type: "record",
      label: "Histórico",
      component: (record: any) => <></>,
    },
    changeOperationFrequency: {
      type: "record",
      label: "Frequências",
      condition: (record: any) => record.isActivated || record.isConnected,
      modalComponent: ({ record }: RecordProps) => (
        <ModalOperationFrequency gatewayId={record.gatewayId} />
      ),
    },
    changeParameters: {
      type: "record",
      label: "Parâmetros",
      condition: (record: any) => record.isActivated || record.isConnected,
      modalComponent: ({ record }: RecordProps) => (
        <ModalGatewayWindow gatewayId={record.gatewayId} />
      ),
    },
    changeUpdatingMode: {
      name: "changeUpdatingMode",
      type: "record",
      label: "Atualizar",
      condition: (record: any) => record.isActivated || record.isConnected,
      component: ({ resource }: PageProps) => (
        <BoltChangeUpdatingMode resource={resource} />
      ),
    },
  },
  tabelao: "GATEWAYS",
  properties: {
    gatewayId: {
      id: "gatewayId",
      type: "TEXT",
      label: "UUID",
      isPrimaryKey: true,
      isVisible: {
        filter: true,
      },
    },
    lastUpdate: {
      id: "date",
      type: "DATE_PERIOD",
      label: "Ulitma Atualização",
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    isActivated: {
      id: "isActivated",
      type: "SELECT",
      label: "Staus Ativação",
      component: {
        list: ({ record, id }: any) => (
          <ListBoolean
            record={record}
            id={id}
            true="Ativado"
            false="Desativado"
          />
        ),
      },
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: true, label: "Ativado" },
        { value: false, label: "Desativado" },
      ],
    },
    isConnected: {
      id: "isConnected",
      type: "SELECT",
      label: "Staus Conexão",
      component: {
        list: ({ record, id }: any) => (
          <ListBoolean record={record} id={id} true="Online" false="Offline" />
        ),
      },
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: true, label: "Online" },
        { value: false, label: "Offline" },
      ],
    },
    connectivityPercent: {
      id: "connectivityPercent",
      type: "RANGE",
      label: "Conectividade (Dia)",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && `${Math.min(Number(record[id]), 100).toFixed(2)}%`,
    },
    healthCheckLastHourPercentage: {
      id: "healthCheckLastHourPercentage",
      type: "RANGE",
      label: "Conectividade (Hora)",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && `${Math.min(Number(record[id]), 100).toFixed(2)}%`,
    },
    // countHealthCheckLastHour: {
    //   id: "countHealthCheckLastHour",
    //   type: "RANGE",
    //   label: "Health Checks (Hora)",
    //   isVisible: {
    //     list: true,
    //     filter: true,
    //   },
    // },
    sdCardStatusCount: {
      id: "sdCardStatusCount",
      type: "RANGE",
      label: "Falha SDCard",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && `${record[id]}`,
    },
    healthCheckDate: {
      id: "healthCheckDate",
      type: "DATE_PERIOD",
      label: "Ultima Conexão",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    id: {
      id: "gatewayId",
      type: "TEXT",
      label: "ID",
      isVisible: {
        list: true,
      },
    },
    sdCardMID: {
      id: "sdCardMID",
      type: "SELECT",
      options: [
        { value: "111", label: "Preto IBBx" },
        { value: "3", label: "SanDisk Ultra" },
        { value: "159", label: "Industrial" },
      ],
      isVisible: {
        filter: true,
        list: false,
        csv: false,
      },
      label: "SdCard",
    },
    sdCardType: {
      id: "sdCardType",
      type: "TEXT",
      isVisible: {
        filter: false,
        list: true,
      },
      label: "SdCard",
      formatedValue: ({ record, id }: RecordProps) => record[id] || "Not_Found",
    },
    sdCardMIDText: {
      id: "sdCardMIDText",
      type: "TEXT",
      label: "sdCardMID",
      isVisible: {
        filter: false,
        list: true,
      },
      formatedValue: ({ record, id }: RecordProps) => record["sdCardMID"],
    },
    sdCardSize: {
      id: "sdCardSize",
      type: "TEXT",
      label: "sdCardSize",
      isVisible: {
        filter: false,
        list: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] ? record[id] : "",
    },
    wasDataStorageFormated: {
      id: "wasDataStorageFormated",
      type: "TEXT",
      label: "Formated?",
      isVisible: {
        filter: false,
        list: true,
      },
    },
    firmware: {
      id: "firmware",
      type: "TEXT",
      label: "Firmware",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    companyId: {
      id: "companyId",
      type: "SELECT_HIEARCHY",
      label: "Empresa",
      endpoint: "/companies-options",
      resetOnChange: ["facilityId", "assetId", "positionId"],
      isVisible: {
        list: false,
        filter: true,
      },
    },
    companyName: {
      id: "companyName",
      label: "Empresa",
      isVisible: {
        list: true,
        filter: false,
      },
      openLink: ({ record, id }: any) =>
        record["companyId"]
          ? `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${record["companyId"]}/facilities`
          : null,
    },
    facilityId: {
      id: "facilityId",
      type: "SELECT_HIEARCHY",
      label: "Unidade",
      endpoint: "/facilities-options",
      parent: "companyId",
      resetOnChange: ["assetId", "positionId"],
      isVisible: {
        list: false,
        filter: true,
      },
    },
    facilityName: {
      id: "facilityName",
      label: "Unidade",
      isVisible: {
        list: true,
        filter: false,
      },
      openLink: ({ record, id }: any) =>
        record["companyId"] && record["facilityId"]
          ? `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${record["companyId"]}/facilities/${record["facilityId"]}/assets?tab=assets&sidetab=bolts`
          : null,
    },
    sector: {
      id: "sector",
      label: "Setor",
      widtn: "200px",
      isVisible: {
        list: true,
        filter: false,
      },
    },
    name: {
      id: "name",
      type: "TEXT",
      label: "Nome",
      isVisible: {
        list: true,
        filter: true,
      },
    },

    radioWindowNumBoardSlots: {
      id: "radioWindowNumBoardSlots",
      type: "RANGE",
      label: "Quantidade de Slots",
      isVisible: {
        list: true,
        filter: false,
      },
    },
    configWindow: {
      id: "configWindow",
      type: "RANGE",
      label: "Duração Janela de Configuração",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] ? `${Math.floor(+record[id] / 60)}min` : "",
    },
    radioWindowInterval: {
      id: "radioWindowInterval",
      type: "RANGE",
      label: "Intervalo entre Transmissões Globais",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] ? `${Math.floor(+record[id] / 60)}min` : "",
    },
    dataAcquisitionInterval: {
      id: "dataAcquisitionInterval",
      type: "RANGE",
      label: "Aquisição entre Globais",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] ? `${Math.floor(+record[id] / 60)}min` : "",
    },
    dynamicTxInterval: {
      id: "dynamicTxInterval",
      type: "RANGE",
      label: "Intervalo entre Dinâmicas",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] ? `${Math.floor(+record[id] / 60)}min` : "",
    },
    operationFrequency: {
      id: "operationFrequency",
      label: "Frequência de Operação MHz",
      type: "SELECT",
      options: [
        { value: 902.5, label: "902.5" },
        { value: 903.0, label: "903.0" },
        { value: 903.5, label: "903.5" },
        { value: 904.0, label: "904.0" },
        { value: 904.5, label: "904.5" },
        { value: 905.0, label: "905.0" },
        { value: 905.5, label: "905.5" },
        { value: 906.0, label: "906.0" },
        { value: 906.5, label: "906.5" },
        { value: 907.0, label: "907.0" },
        { value: 907.5, label: "907.5" },
        { value: 915.0, label: "915.0" },
        { value: 915.5, label: "915.5" },
        { value: 916.0, label: "916.0" },
        { value: 916.5, label: "916.5" },
        { value: 917.0, label: "917.0" },
        { value: 917.5, label: "917.5" },
        { value: 918.0, label: "918.0" },
        { value: 918.5, label: "918.5" },
        { value: 919.0, label: "919.0" },
        { value: 919.5, label: "919.5" },
        { value: 920.0, label: "920.0" },
        { value: 920.5, label: "920.5" },
        { value: 921.0, label: "921.0" },
        { value: 921.5, label: "921.5" },
        { value: 922.0, label: "922.0" },
        { value: 922.5, label: "922.5" },
        { value: 923.0, label: "923.0" },
        { value: 923.5, label: "923.5" },
        { value: 924.0, label: "924.0" },
        { value: 924.5, label: "924.5" },
        { value: 925.0, label: "925.0" },
        { value: 925.5, label: "925.5" },
        { value: 926.0, label: "926.0" },
        { value: 926.5, label: "926.5" },
        { value: 927.0, label: "927.0" },
        { value: 927.5, label: "927.5" },
        { value: 928.0, label: "928.0" },
        { value: 928.5, label: "928.50" },
        { value: 929.0, label: "929.00" },
        { value: 929.5, label: "929.50" },
        { value: 930.0, label: "930.00" },
        { value: 930.5, label: "930.50" },
        { value: 931.0, label: "931.00" },
        { value: 931.5, label: "931.50" },
        { value: 932.0, label: "932.00" },
        { value: 932.5, label: "932.50" },
        { value: 933.0, label: "933.00" },
        { value: 933.5, label: "933.50" },
        { value: 934.0, label: "934.00" },
        { value: 934.5, label: "934.50" },
        { value: 935.0, label: "935.00" },
        { value: 935.5, label: "935.50" },
        { value: 936.0, label: "936.00" },
        { value: 936.5, label: "936.50" },
        { value: 937.0, label: "937.00" },
        { value: 937.5, label: "937.50" },
        { value: 938.0, label: "938.00" },
        { value: 938.5, label: "938.50" },
        { value: 939.0, label: "939.00" },
        { value: 939.5, label: "939.50" },
        { value: 940.0, label: "940.00" },
        { value: 940.5, label: "940.50" },
        { value: 941.0, label: "941.00" },
        { value: 941.5, label: "941.50" },
        { value: 942.0, label: "942.00" },
        { value: 942.5, label: "942.50" },
        { value: 943.0, label: "943.00" },
        { value: 943.5, label: "943.50" },
        { value: 944.0, label: "944.00" },
        { value: 944.5, label: "944.50" },
        { value: 945.0, label: "945.00" },
        { value: 945.5, label: "945.50" },
        { value: 946.0, label: "946.00" },
        { value: 946.5, label: "946.50" },
        { value: 947.0, label: "947.00" },
        { value: 947.5, label: "947.50" },
        { value: 948.0, label: "948.00" },
      ],
      isVisible: {
        list: true,
        filter: true,
      },
    },
    cloudConnection: {
      id: "cloudConnection",
      type: "TEXT",
      label: "Tipo de Conexão Cloud",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    uptimeDays: {
      id: "uptimeDays",
      type: "RANGE",
      label: "Tempo Ligado",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => {
        if (record[id] !== 0) {
          return `${record[id]} ${record[id] === 1 ? " dia" : " dias"}`;
        }
        const hours = "uptimeHours";
        if (record[hours] !== 0) {
          return `${record[hours]} ${record[hours] === 1 ? " hora" : " horas"}`;
        }
        if (record["uptime"] && record[hours] === 0 && record[id] === 0) {
          return "Poucos Minutos";
        }
        return "";
      },
    },
    rssi: {
      id: "connectionRssi",
      type: "RANGE",
      label: "Potência sinal cloud",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    linkQuality: {
      id: "linkQuality",
      type: "TEXT",
      label: "Qualidade link cloud",
      isVisible: {
        list: false,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) => "?",
    },
    boardsSchedules: {
      id: "boardsSchedules",
      type: "RANGE",
      label: "Endpoints Sincronizados",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    temperature: {
      id: "temperature",
      type: "RANGE",
      label: "Temperatura",
      isVisible: {
        list: false,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) => "?",
    },
    powerSupply: {
      id: "powerSupply",
      type: "TEXT",
      label: "Alimentação Elétrica",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    batteryVoltage: {
      id: "batteryVoltage",
      type: "RANGE",
      label: "Tensão da bateria",
      isVisible: {
        list: true,
        filter: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <span style={{ color: "#FFF" }}>{record[id] || ""}</span>
        ),
      },
    },
    recallForecast: {
      id: "recallForecast",
      type: "DATE_PERIOD",
      label: "Recall programado",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    lastRecallAt: {
      id: "lastRecallAt",
      type: "DATE_PERIOD",
      label: "Ultimo Recall realizado",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    isRecall: {
      id: "isRecall",
      type: "SELECT",
      label: "Recall realizado",
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] ? "Sim" : "Não",
      isVisible: {
        list: false,
        filter: false,
      },
      options: [
        { value: true, label: "Sim" },
        { value: false, label: "Não" },
      ],
    },
    recallMotive: {
      id: "recallMotive",
      type: "TEXT",
      label: "Motivo Recall",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    partnumber: {
      id: "partnumber",
      type: "SELECT",
      label: "Partnumber",
      isVisible: {
        list: true,
      },
      options: [
        { value: "spectrapic24915v1", label: "spectrapic24915v1" },
        { value: "connectpic24915v1 ", label: "connectpic24915v1" },
        { value: "connectcube915v1", label: "connectcube915v1" },
        { value: "spectramensor", label: "spectramensor" },
      ],
    },
    manufacturer: {
      id: "manufacturer",
      type: "SELECT",
      label: "Fabricante",
      isVisible: {
        list: true,
        filter: true,
      },
      options: [{ value: "Etronic", label: "Etronic" }],
    },
    batch: {
      id: "batch",
      type: "TEXT",
      label: "Lote",
      isVisible: {
        list: false,
        filter: false,
      },
    },
    simCCID: {
      id: "simCCID",
      type: "TEXT",
      label: "Sim CCID",
      isVisible: {
        list: true,
        filter: false,
      },
    },
  },
};
