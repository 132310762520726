export interface IdeviceFFTs {
  id: number;
  fftImageUrl: string;
  requestedAt: string;
  positionName: string;
  positionId: number;
  status: StatusEnum;
}

export enum StepEnum {
  PDF = "PDF",
  LIST = "LIST",
}

export enum StatusEnum {
  FAILED = "FAILED",
  PROCESSED = "PROCESSED",
  PROCESSING = "PROCESSING",
  NOT_STARTED = "NOT_STARTED",
}

export interface IFFTReportContext {
  closeModal: () => void;
  setStep: any;
  step: StepEnum;
  qualityInspectionId: number;
  burninQtd: number;
  renderFFTUrl: string[];
  setRenderFFTUrl: any;
}

export const StatusDescriptions: Record<StatusEnum, string> = {
  [StatusEnum.NOT_STARTED]: "Não iniciado",
  [StatusEnum.PROCESSING]: "FFT em andamento",
  [StatusEnum.PROCESSED]: "FFT gerado",
  [StatusEnum.FAILED]: "Falha",
};
