import { cloneElement, useState } from "react";
import { Modal } from "../../../../components/Modal";
import { MdClose } from "react-icons/md";
import { CloseButton, Container } from "./style";
import { FileUpload } from "./FileUpload";
import BatchActivationContext from "./context";
import { ConfirmedBatch } from "./ConfirmedBatch";
import {
  BatchActivationProps,
  IBactchActivatorErrors,
  StepsBatchActivationEnum,
} from "./model";
import { BatchErrors } from "./BatchErrors";

export function BatchActivation({
  onClose,
  companyId,
  children,
  burninSize,
  devicesQuantity,
}: BatchActivationProps) {
  const [show, setShow] = useState<boolean>(false);
  const [step, setStep] = useState<StepsBatchActivationEnum>(
    StepsBatchActivationEnum.UPLOAD_FILE
  );
  const [batchErrors, setBatchErrors] = useState<IBactchActivatorErrors[]>([]);

  const closeModal = () => {
    setStep(StepsBatchActivationEnum.UPLOAD_FILE);
    setShow(false);
    onClose();
  };
  return (
    <>
      {cloneElement(children, { onClick: () => setShow(true) })}
      <Modal
        handleClose={() => setShow(!show)}
        show={show}
        changeFotter
        backdrop="static"
      >
        <BatchActivationContext.Provider
          value={{
            closeModal,
            companyId,
            burninSize,
            devicesQuantity,
            step,
            setStep,
            batchErrors,
            setBatchErrors,
          }}
        >
          <Container>
            <header>
              <CloseButton onClick={closeModal}>
                <MdClose />
              </CloseButton>
            </header>
            {step === StepsBatchActivationEnum.UPLOAD_FILE && <FileUpload />}
            {step === StepsBatchActivationEnum.SUCCESS && <ConfirmedBatch />}
            {step === StepsBatchActivationEnum.ERROS && <BatchErrors />}
          </Container>
        </BatchActivationContext.Provider>
      </Modal>
    </>
  );
}
