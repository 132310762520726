import { useState } from "react";
import { Modal } from "../../../../components/Modal";
import { Container, Title, CloseButton, Button } from "./styles";
import { MdClose } from "react-icons/md";
import { RenderFFTPDF } from "./modals/RenderFFTPDF";
import { ListDevicesFFTs } from "./modals/ListDevicesFFTs";
import { StepEnum } from "./model";
import FFTReportContext from "./context";

export const ExportFFTReport = ({ record }: any) => {
  const [show, setShow] = useState(false);
  const [step, setStep] = useState<StepEnum>(StepEnum.LIST);
  const [renderFFTUrl, setRenderFFTUrl] = useState<string[]>([]);

  const closeModal = () => {
    setShow(false);
    setStep(StepEnum.LIST);
    setRenderFFTUrl([]);
  };

  return (
    <FFTReportContext.Provider
      value={{
        closeModal,
        step,
        setStep,
        qualityInspectionId: record?.record?.id,
        burninQtd: record?.record?.deviceQuantity,
        renderFFTUrl,
        setRenderFFTUrl,
      }}
    >
      <span onClick={() => setShow(true)}>Relatório FFT's</span>
      <Modal
        handleClose={closeModal}
        show={show}
        confirmLabel="Baixar"
        size="lg"
        disabledConfirm={false}
        changeFotter
      >
        <Container>
          <Title>
            Relatório FFT ({record?.record?.name || ""})
            <CloseButton onClick={closeModal}>
              <MdClose size={24} />
            </CloseButton>
          </Title>
          {step === StepEnum.LIST && <ListDevicesFFTs />}
          {step === StepEnum.PDF && <RenderFFTPDF />}
        </Container>
      </Modal>
    </FFTReportContext.Provider>
  );
};
