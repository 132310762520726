import { Document, Page, Image, PDFViewer } from "@react-pdf/renderer";
import { useFFTReport } from "../context";
import { Footer, Button } from "../styles";
import { StepEnum } from "../model";

export const RenderFFTPDF = () => {
  const { renderFFTUrl, setRenderFFTUrl, setStep } = useFFTReport();

  const handleBack = () => {
    setStep(StepEnum.LIST);
    setRenderFFTUrl([]);
  };

  function renderDocument() {
    return (
      <Document>
        {(renderFFTUrl || []).map((url, index) => (
          <Page key={index} size="A4">
            <Image src={url} />
          </Page>
        ))}
      </Document>
    );
  }

  return (
    <>
      <PDFViewer style={{ width: "100%", height: "600px" }}>
        {renderDocument()}
      </PDFViewer>
      <Footer style={{ justifyContent: "center" }}>
        <Button onClick={handleBack}>Voltar</Button>
      </Footer>
    </>
  );
};
