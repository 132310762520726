import {
  MdLineAxis,
  MdOutlineQuestionMark,
  MdOutlineRemoveCircle,
  MdOutlineRestore,
} from "react-icons/md";
import {
  Badge,
  Button,
  Header,
  WrapperBadge,
  Footer,
  List,
  ListItem,
  ListCell,
  ListHeader,
  Loader,
  EmptyData,
} from "../styles";
import { useEffect, useMemo, useState } from "react";
import useApi from "../../../../../hooks/useApi";
import { useFFTReport } from "../context";
import {
  IdeviceFFTs,
  StatusDescriptions,
  StatusEnum,
  StepEnum,
} from "../model";
import { differenceInMinutes, format, min, parseISO } from "date-fns";
import { Spinner } from "react-bootstrap";
import { FaFile } from "react-icons/fa";
import { Tooltip } from "../../../../monitorator/components/Tooltip";
import { success } from "../../../../../components/Toast";

export const ListDevicesFFTs = () => {
  const [deviceFFT, setDeviceFFT] = useState<IdeviceFFTs[]>();
  const {
    qualityInspectionId,
    closeModal,
    burninQtd,
    setRenderFFTUrl,
    setStep,
  } = useFFTReport();
  const { request, processing } = useApi({
    path: `/infra/quality-inspections/${qualityInspectionId}/devices/ffts-images`,
  });

  useEffect(() => {
    request({
      method: "get",
    }).then((res) => {
      setDeviceFFT(res);
    });
  }, []);

  const statusInfo = useMemo(() => {
    const status = {
      [StatusEnum.NOT_STARTED]: 0,
      [StatusEnum.PROCESSING]: 0,
      [StatusEnum.PROCESSED]: 0,
      [StatusEnum.FAILED]: 0,
    };
    if (deviceFFT && deviceFFT?.length > 0) {
      deviceFFT.forEach((device: IdeviceFFTs) => {
        if (device?.status) {
          status[device.status]++;
        }
      });
    }
    return status;
  }, [deviceFFT]);

  const hasDevices = useMemo(() => {
    return deviceFFT && deviceFFT?.length > 0;
  }, [deviceFFT]);

  const EarliestRequestDate = useMemo(() => {
    if (deviceFFT && deviceFFT?.length > 0) {
      const convertDates = deviceFFT.map((device: IdeviceFFTs) =>
        parseISO(device.requestedAt)
      );
      return min(convertDates);
    }
  }, [deviceFFT]);

  const handleSubmitFFT = (devices: number[]) => {
    request({
      method: "put",
      body: devices,
    }).then(() => {
      success("Sensores inseridos na fila para a geração de FFTs.");
      refreshList();
    });
  };

  const handleCreatePDF = (fftUrl: string[]) => {
    setRenderFFTUrl(fftUrl);
    setStep(StepEnum.PDF);
  };

  const refreshList = () => {
    request({
      method: "get",
    }).then((res) => {
      setDeviceFFT(res);
    });
  };

  return (
    <>
      <Header>
        <WrapperBadge>
          <Badge variant="PROCESSED" isVisible={statusInfo.PROCESSED < 1}>
            <MdLineAxis size={18} />
            {statusInfo.PROCESSED} FFT gerado
          </Badge>
          <Badge variant="IN_PROGRESS" isVisible={statusInfo.PROCESSING < 1}>
            <MdOutlineRestore size={18} />
            {statusInfo.PROCESSING} FFT em adamento
          </Badge>
          <Badge variant="NOT_STARTED" isVisible={statusInfo.NOT_STARTED < 1}>
            <MdOutlineRemoveCircle size={18} />
            {statusInfo.NOT_STARTED} FFT's não iniciados
          </Badge>
        </WrapperBadge>
      </Header>
      {processing ? (
        <Loader>
          <Spinner
            style={{
              height: "32px",
              width: "32px",
              marginLeft: "8px",
              marginBottom: "-4px",
            }}
            animation="border"
          />
        </Loader>
      ) : (
        <List>
          <ListHeader>
            <span> Ponto</span>
            <span> Data do últimoplot</span>
            <span> Status</span>
            <span> ações</span>
            <span></span>
          </ListHeader>
          <>
            {(deviceFFT || []).map((device: IdeviceFFTs) => (
              <ListItem variant={device.status}>
                <ListCell>{device.positionName}</ListCell>
                <ListCell>
                  {format(new Date(device.requestedAt), "dd/MM/yyyy HH:mm")}
                </ListCell>
                <ListCell>{StatusDescriptions[device.status]}</ListCell>
                <ListCell>
                  <ToolTipFFTButtonDisabled
                    show={
                      (device.requestedAt &&
                        differenceInMinutes(
                          new Date(),
                          new Date(device.requestedAt)
                        ) < 60) ||
                      processing
                    }
                  >
                    <Button
                      disabled={
                        (device.requestedAt &&
                          differenceInMinutes(
                            new Date(),
                            new Date(device.requestedAt)
                          ) < 60) ||
                        processing
                      }
                      onClick={() => {
                        handleSubmitFFT([device.id]);
                      }}
                      style={{
                        fontSize: "0.75rem",
                        padding: "0.188rem 0.313rem",
                      }}
                    >
                      Gerar FFT
                    </Button>
                  </ToolTipFFTButtonDisabled>
                </ListCell>
                <ListCell>
                  <Button
                    disabled={device.status !== StatusEnum.PROCESSED}
                    onClick={() => {
                      handleCreatePDF([device.fftImageUrl]);
                    }}
                    style={{
                      fontSize: "0.75rem",
                      padding: "0.188rem 0.313rem",
                    }}
                  >
                    Visualizar FFT
                  </Button>
                </ListCell>
              </ListItem>
            ))}
            {!hasDevices && !processing && (
              <EmptyData>
                <FaFile style={{ fontSize: "24px" }} />
                sem dados
              </EmptyData>
            )}
          </>
        </List>
      )}
      <Footer>
        <Button onClick={() => closeModal()}>Fechar</Button>
        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          <Button onClick={refreshList}>Atualizar</Button>
          <Button
            disabled={statusInfo.PROCESSED < burninQtd}
            onClick={() => {
              if (hasDevices) {
                const devicesIDs: string[] =
                  deviceFFT?.map((device: IdeviceFFTs) => device.fftImageUrl) ||
                  [];
                handleCreatePDF(devicesIDs);
              }
            }}
          >
            Exportar todos os FFT's PDF
          </Button>

          <ToolTipFFTButtonDisabled
            show={
              (EarliestRequestDate &&
                differenceInMinutes(new Date(), EarliestRequestDate) < 60) ||
              !hasDevices ||
              processing
            }
          >
            <Button
              disabled={
                (EarliestRequestDate &&
                  differenceInMinutes(new Date(), EarliestRequestDate) < 60) ||
                !hasDevices ||
                processing
              }
              onClick={() => {
                if (hasDevices) {
                  const devicesIDs: number[] =
                    deviceFFT?.map((device: IdeviceFFTs) => device.id) || [];
                  handleSubmitFFT(devicesIDs);
                }
              }}
            >
              Gerar FFTs para Todos os Itens
            </Button>
          </ToolTipFFTButtonDisabled>
        </div>
      </Footer>
    </>
  );
};

const ToolTipFFTButtonDisabled = ({ show, children }: any) => {
  return show ? (
    <Tooltip
      disabled={false}
      tooltip={
        "A solicitação mais antiga de FFT's foi feita há menos de 1 hora."
      }
      position="top"
    >
      <span style={{ display: "flex" }}>{children}</span>
    </Tooltip>
  ) : (
    <>{children}</>
  );
};
