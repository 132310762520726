import { createContext, useContext } from "react";
import { IFFTReportContext, StepEnum } from "./model";

const DEFAULT: IFFTReportContext = {
  closeModal: () => {},
  setStep: () => {},
  step: StepEnum.LIST,
  qualityInspectionId: 0,
  burninQtd: 0,
  renderFFTUrl: [],
  setRenderFFTUrl: () => {},
};

const FFTReportContext = createContext<IFFTReportContext>(DEFAULT);

export const useFFTReport = () => {
  const context = useContext(FFTReportContext);
  if (context === null) {
    throw new Error(
      "FFTReportContext deve ser usado dentro do modal batch activation"
    );
  }
  return context;
};

export default FFTReportContext;
