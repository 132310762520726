import { RecordProps } from "../../../../types/default";
import { ListContainer, GatewayId } from "./styles";

export const ListGateway = ({ id, record }: RecordProps) => {
  const { name, gatewayId, companyId, facilityId } = record;

  return (
    <ListContainer
      href={`${process.env.REACT_APP_RETINA_WEB_URL}/companies/${companyId}/facilities/${facilityId}/connectivity?boltId=${gatewayId}`}
      target="_blank"
    >
      <div>
        <span>Nome: </span>
        <span>{name}</span>
      </div>
      <div>
        <span>ID: </span>
        <GatewayId>{gatewayId}</GatewayId>
      </div>
    </ListContainer>
  );
};
