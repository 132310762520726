import { useState, useRef, useEffect, ReactElement, useCallback } from "react";
import { MdErrorOutline, MdUploadFile } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import {
  DropZone,
  Title,
  DropZoneInfo,
  GuideAlert,
  Button,
  FileInfo,
  GuideLink,
  ErrorList,
} from "./style";
import { Tooltip } from "../../../../modules/monitorator/components/Tooltip";
import useApi from "../../../../hooks/useApi";
import { CSVLink } from "react-csv";
import { Spinner } from "react-bootstrap";
import { useBatchActivation } from "./context";
import {
  IavailableSlots,
  IBactchActivatorErrors,
  IGuideColumns,
  StepsBatchActivationEnum,
} from "./model";
import { error as errorToast } from "../../../../components/Toast";
import { fileValidation, readCSVFile } from "./HandleFile";

export function FileUpload() {
  const qualityInspectionId = useParams()?.id;
  const [guideData, setGuideData] = useState<IGuideColumns[]>([]);
  const [fileRejectionCustom, setFileRejectionCustom] =
    useState<ReactElement | null>(null);
  const {
    companyId,
    setStep,
    closeModal,
    setBatchErrors,
    burninSize,
    devicesQuantity,
  } = useBatchActivation();
  const csvRef = useRef<any>(null);
  const clearFile = (acceptedFiles: any) => {
    acceptedFiles.pop();
    csvRef.current.value = "";
  };
  const onDrop = useCallback(async (acceptedFiles: any) => {
    setFileRejectionCustom(null);
    const result = await fileValidation({ acceptedFiles, burninSize });
    if (!result.isValid) {
      setFileRejectionCustom(() => (
        <ul>
          <li>{result.message}</li>
        </ul>
      ));
      clearFile(acceptedFiles);
    }
  }, []);

  const {
    fileRejections,
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
    maxFiles: 1,
    maxSize: 1048576, // 1 megabytes
    multiple: false,
  });

  const { request: requestDeviceSlots, processing: processingDeviceSlots } =
    useApi({
      path: `/infra/quality-inspections/company`,
    });

  const {
    request: requestBatchActivation,
    processing: processingBatchActivation,
  } = useApi({
    path: `/infra/company/${companyId}/quality-inspections/${qualityInspectionId}/devices/batch-activate`,
  });

  async function getDeviceSlots() {
    return await requestDeviceSlots({
      method: "get",
      pathParameters: `${Number(companyId)}/positions-status`,
    });
  }

  const getGuide = async () => {
    const size = burninSize - devicesQuantity;
    if (size < 1) {
      errorToast(`Não há espaço disponível no burn-in`);
      return;
    }

    await getDeviceSlots().then((response: IavailableSlots[]) => {
      let guideData = response
        .filter((slot: IavailableSlots) => slot.positioAvailable)
        .map((slot) => ({
          positionId: slot.positionId,
          positionName: slot.positionName,
          activatorId: "",
        }));
      guideData =
        size < guideData?.length ? guideData.slice(0, size) : guideData;
      if (guideData?.length < 1) {
        errorToast(`Não há pontos disponíveis para realizar a ativação`);
      } else {
        setGuideData(guideData);
      }
    });
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      Arquivo: {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>Mensagem de error: {e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const handleActivation = async () => {
    const data = await readCSVFile(acceptedFiles[0]);

    requestBatchActivation({
      method: "post",
      body: data,
    })
      .then((res: IBactchActivatorErrors[]) => {
        const hasError = (res || []).find(
          (error: IBactchActivatorErrors) => !!error?.error
        );
        if (hasError) {
          setBatchErrors(res);
          setStep(StepsBatchActivationEnum.ERROS);
        } else {
          setStep(StepsBatchActivationEnum.SUCCESS);
        }
      })
      .catch((error) => {
        errorToast(
          `Erro ao ativar em massa: ${JSON.stringify(
            error?.response?.data || error.message
          )}`
        );
        closeModal();
      });
  };

  useEffect(() => {
    if (guideData.length > 0) {
      csvRef.current.link.click();
      setGuideData([]);
    }
  }, [guideData]);

  return (
    <>
      <Title draggable="true">Ativação em massa de dispositivos</Title>
      <p>
        Baixe o arquivo de guia e preencha a coluna "ActivatorId". Em seguida,
        faça o upload do arquivo logo abaixo. O delimitador CSV deve ser ";" e a
        ordem das colunas não deve ser alterada, sendo elas: PositionId,
        PositionName e ActivatorId. Além disso, o arquivo CSV deve conter o
        cabeçalho (header).
      </p>
      <GuideAlert>
        <Tooltip
          tooltip="O guia será baixado com a quantidade de pontos do burn-in e apenas os pontos disponíveis para atualização. Será necessário preencher apenas a coluna de 'ActivatorId'."
          position="top"
        >
          <span style={{ display: "flex" }}>
            <MdErrorOutline />
          </span>
        </Tooltip>
        <p>
          Baixar guia{" "}
          <GuideLink
            onClick={async () => {
              try {
                await getGuide();
              } catch (error) {
                errorToast(`Erro ao gerar o guia: ${JSON.stringify(error)}`);
              }
            }}
          >
            aqui
          </GuideLink>
          {processingDeviceSlots && (
            <Spinner
              style={{
                height: "20px",
                width: "20px",
                marginLeft: "8px",
                marginBottom: "-4px",
              }}
              animation="border"
            />
          )}
        </p>
        <CSVLink
          ref={csvRef}
          data={guideData}
          filename={`guia-burnin-${qualityInspectionId}.csv`}
          enclosingCharacter=""
          separator={";"}
        ></CSVLink>
      </GuideAlert>
      <DropZone {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <MdUploadFile size={80} />
        {isDragActive ? (
          <DropZoneInfo>Solte o arquivo aqui ...</DropZoneInfo>
        ) : (
          <DropZoneInfo>
            {acceptedFiles?.length > 0 ? (
              <FileInfo>
                {`Arquivo: ${acceptedFiles[0].name}`} <br />{" "}
                {`Tamanho: ${acceptedFiles[0].size} bytes`}
              </FileInfo>
            ) : (
              "Arraste e solte o arquivo de guia aqui, ou clique para selecionar arquivos"
            )}
          </DropZoneInfo>
        )}
      </DropZone>
      <ErrorList>
        {fileRejectionItems}
        {fileRejectionCustom}
      </ErrorList>
      <Button
        style={{ gap: "0.5rem" }}
        onClick={handleActivation}
        disabled={acceptedFiles?.length < 1 || processingBatchActivation}
      >
        {processingBatchActivation && (
          <Spinner
            style={{
              height: "20px",
              width: "20px",
            }}
            animation="border"
          />
        )}
        Ativar
      </Button>
    </>
  );
}
