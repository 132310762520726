import { RecordProps } from "../../../../types/default";
import { ListContainer } from "./styles";

export const ListPosition = ({ id, record }: RecordProps) => {
  const {
    companyName,
    facilityName,
    assetName,
    positionName,
    positionId,
  } = record;

  return (
    <ListContainer
      href={`${process.env.REACT_APP_RETINA_WEB_URL}/positions/${positionId}`}
      target="_blank"
    >
      <div>
        <span>Empresa: </span>
        <span title={companyName}>{companyName}</span>
      </div>
      <div>
        <span>Unidade: </span>
        <span title={facilityName}>{facilityName}</span>
      </div>
      <div>
        <span>Equipamento: </span>
        <span title={assetName}>{assetName}</span>
      </div>
      <div>
        <span>Ponto: </span>
        <span title={positionName}>{positionName}</span>
      </div>
      <div>
        <span>ID Ponto: </span>
        <span>{positionId}</span>
      </div>
    </ListContainer>
  );
};
