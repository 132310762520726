import { ListBoolean } from "../../../components/List/ListBoolean";
import { ExportCompaniesCSV } from "../modals/ExportCompaniesCSV";

export const CompaniesResource = {
  title: "Empresas",
  urlPath: "/web/companies",
  apiPath: "/web/companies",
  actions: {
    new: { isVisible: false },
    edit: { isVisible: false },
    delete: { isVisible: false },
    show: { isVisible: false },
    exportCSV: {
      type: "resource",
      name: "exportCSV",
      label: "Exportar CSV",
      modalComponent: () => <ExportCompaniesCSV />,
    },
  },
  properties: {
    id: {
      id: "companyId",
      type: "TEXT",
      label: "ID",
      isPrimaryKey: true,
      isVisible: {
        list: true,
        filter: true,
      },
    },
    companyId: {
      id: "companyId",
      label: "EMPRESA",
      type: "SELECT_API",
      endpoint: "/companies-options",
      isVisible: {
        filter: true,
      },
    },
    companyName: {
      id: "companyName",
      type: "TEXT",
      label: "EMPRESA",
      isVisible: {
        list: true,
      },
    },
    countUsers: {
      id: "countUsers",
      type: "TEXT",
      label: "USUARIOS",
      isVisible: {
        list: true,
      },
    },
    countFacilities: {
      id: "countFacilities",
      type: "TEXT",
      label: "UNIDADES",
      isVisible: {
        list: true,
      },
    },
    countAssets: {
      id: "countAssets",
      type: "TEXT",
      label: "ATIVOS",
      isVisible: {
        list: true,
      },
    },
    countPositions: {
      id: "countPositions",
      type: "TEXT",
      label: "PONTOS CADASTRADOS",
      isVisible: {
        list: true,
      },
    },
    countActivatedPositions: {
      id: "countActivatedPositions",
      type: "TEXT",
      label: "PONTOS ATIVADOS",
      isVisible: {
        list: true,
      },
    },
    countConnectedPositions: {
      id: "countConnectedPositions",
      type: "TEXT",
      label: "PONTOS CONECTADOS",
      isVisible: {
        list: true,
      },
    },
    isDeleted: {
      id: "isDeleted",
      type: "SELECT",
      label: "DELETADA?",
      isVisible: {
        list: true,
        filter: true,
      },
      component: {
        list: ({ record, id }: any) => <ListBoolean record={record} id={id} />,
      },
      options: [
        { value: true, label: "Sim" },
        { value: false, label: "Não" },
      ],
    },
  },
};
