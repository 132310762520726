import { enUS, es, ptBR } from "date-fns/locale";

export function intlDateFormat(
  date: number | Date | string | null | undefined,
  options?: Intl.DateTimeFormatOptions
) {
  const userOptions = new Intl.DateTimeFormat().resolvedOptions();
  let locale = userOptions.locale;

  if (!date) {
    return "";
  }

  const defaultOptions: Intl.DateTimeFormatOptions = {
    dateStyle: "short",
    timeStyle: "medium",
  };

  try {
    return new Intl.DateTimeFormat(locale, options || defaultOptions).format(
      new Date(date)
    );
  } catch {
    return "";
  }
}

export function formatDate(date: any, options: any = { type: "DEFAULT" }) {
  let { locale } = new Intl.DateTimeFormat().resolvedOptions();

  const { type } = options;
  if (!date) {
    return "";
  }

  let dateOptions: any = {
    dateStyle: "short",
  };

  if (type === "FULL") {
    dateOptions = { timeStyle: "short", dateStyle: "short" };
  }

  if (type === "HOUR") {
    dateOptions = { timeStyle: "short" };
  }

  if (type === "SHORT") {
    dateOptions = {
      month: "short",
      day: "numeric",
    };
  }

  if (type === "DATE") {
    dateOptions = { dateStyle: "short" };
  }

  if (!isNaN(date)) {
    date = +date;
  }

  try {
    return new Intl.DateTimeFormat(locale, dateOptions).format(new Date(date));
  } catch {
    return "";
  }
}

function isAmericanFormat() {
  const { locale } = new Intl.DateTimeFormat().resolvedOptions();
  const [, country] = locale.split("-");

  return locale === "en-CA" || ["US", "PH"].includes(country);
}

export function parseDateFormat(dateFormat: string) {
  if (isAmericanFormat()) {
    switch (dateFormat) {
      case "dd/MM":
        return "MM/dd";
      case "dd/MM/yyyy":
        return "MM/dd/yyyy";
      case "dd/MM/yyyy HH:mm":
        return "dd/MM/yyyy hh:mm a";
      case "dd/MM/yyyy HH:mm:ss":
        return "MM/dd/yyyy hh:mm:ss a";
      case "dd/MM/yyyy \n HH:mm":
        return "MM/dd/yyyy \n hh:mm a";
      case "HH:mm":
        return "hh:mm a";
    }
  }

  return dateFormat;
}

export function getDateFnsLocaleByLanguage(language: string) {
  switch (language) {
    case "en":
      return enUS;
    case "es":
      return es;
    default:
      return ptBR;
  }
}
