import { PROFILES } from "../../../store/slices/userSlice";
import { ListContainer } from "../styles";
import { ListProps } from "../types";

export const ListUserCompany = ({ record }: ListProps) => {
  const isClienteMaster = [
    PROFILES.CLIENTE_MASTER,
    PROFILES.CLIENTE_MASTER_VIEW,
  ].includes(record.profile);

  const isRepresentante = [PROFILES.REPRESENTANTE].includes(record.profile);

  const isMesaMaster = [PROFILES.MESA_MASTER].includes(record.profile);

  if (isMesaMaster) {
    return <></>;
  }

  if (isRepresentante) {
    return (
      <ListContainer>
        <div>
          <span>Integrador: </span>
          <span>{record.companyName}</span>
        </div>
      </ListContainer>
    );
  }

  if (isClienteMaster) {
    return (
      <ListContainer>
        {record.companies.map((company: any) => (
          <div>
            <span>Empresa: </span>
            <span>
              {company.name} ({company.isReadOnly ? "VIEW" : "EDITOR"})
            </span>
          </div>
        ))}
      </ListContainer>
    );
  }

  return (
    <ListContainer>
      {record.facilities?.map((facility: any) => (
        <>
          <div>
            <span>Empresa: </span>
            <span>{facility?.companyName}</span>
          </div>
          <div>
            <span>Unidade: </span>
            <span>
              {facility?.facilityName} (
              {facility?.isReadOnly ? "VIEW" : "EDITOR"})
            </span>
          </div>
        </>
      ))}
    </ListContainer>
  );
};
