export interface BurninProps {
  uuid: string;
  location: "SIM" | "NÂO" | null;
  case: "SIM" | "NÂO" | null;
  base: "SIM" | "NÂO" | null;
  screw: "SIM" | "NÂO" | null;
  basefixing: "SIM" | "NÂO" | null;
  sticker: "SIM" | "NÂO" | null;
  qrCode: "SIM" | "NÂO" | null;
  connected: "SIM" | "NÂO" | null;
  tableFitting: "SIM" | "NÂO" | null;
}

export enum InspectionStepsEnum {
  INITIAL_VISUAL = "INITIAL_VISUAL",
  BURNIN_STATUS = "BURNIN_STATUS",
  FINAL_VISUAL = "FINAL_VISUAL",
  PACKING = "PACKING",
  FINISHED = "FINISHED",
}

export enum InspectionStatusEnum {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface IInpectionDetails {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  companyId: number;
  companyName: string;
  deviceQuantity: number;
  deviceBatch: string;
  description: string;
  deviceName: string;
  deletedAt: string;
  deletedBy: Date;
  createdAt: string;
  createdBy: Date;
  updatedAt: string;
  updatedBy: Date;
  initialVisualInspectionApprovedBy: Date;
  initialVisualInspectionApprovedAt: string;
  burninStatusApprovedBy: Date;
  burninStatusApprovedAt: string;
  finalVisualInspectionApprovedBy: Date;
  finalVisualInspectionApprovedAt: string;
  packingVisualInspectionApprovedBy: Date;
  packingVisualInspectionApprovedAt: string;
  currentStep: InspectionStepsEnum;
}

type burninDetailsItem = {
  value: number;
  status: boolean;
};
export interface IDevices {
  id?: number;
  qualityInspectionId: number;
  deletedAt: string;
  deletedBy: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  positionId: number;
  positionName: string;
  activatorId: string;
  initialVisualInspectionStatus: InspectionStatusEnum;
  initialVisualInspectionDetails: {
    case: boolean;
    base: boolean;
    screw: boolean;
    baseFixing: boolean;
    sticker: boolean;
    QRCode: boolean;
    deviceTurnedOn: boolean;
    tableFitting: boolean;
  } | null;
  burninStatus: InspectionStatusEnum;
  burninDetails: {
    batteryConsumption: burninDetailsItem;
    batteryVoltage: burninDetailsItem;
    initialBatteryVoltage: burninDetailsItem;
    isConnected: burninDetailsItem;
    lastCollectRSSI: burninDetailsItem;
    lastCollectFFT: burninDetailsItem;
    lastCollectRMS: burninDetailsItem;
    performance: burninDetailsItem;
    temperature: burninDetailsItem;
    userId: string;
  } | null;
  finalVisualInspectionStatus: InspectionStatusEnum;
  finalVisualInspectionDetails: {
    case: boolean;
    base: boolean;
    screw: boolean;
    baseFixing: boolean;
    sticker: boolean;
    QRCode: boolean;
    deviceTurnedOn: boolean;
    tableFitting: boolean;
  } | null;
  packingInspectionStatus: InspectionStatusEnum;
  packingInspectionDetails: {
    position: boolean;
    recharged: boolean;
    screwOnOff: boolean;
    screwM8: boolean;
    bubbleWrap: boolean;
    packaging: boolean;
  } | null;
}

export interface IBurninDetails {
  id: number;
  status: InspectionStatusEnum;
  activatorId: string;
  batteryConsumption: number;
  batteryVoltage: number;
  initialBatteryVoltage: number;
  temperature: number;
  isConnected: boolean;
  lastCollectRSSI: number;
  performance: number;
  positionId: number;
  positionName: string;
  qualityDeviceId: number;
  qualityInspectionId: number;
}
