import { Modal as RBModal, ModalProps as RBModalProps } from "react-bootstrap";
import styled from "styled-components";
// import 'bootstrap/dist/css/bootstrap.min.css';

type ModalProps = RBModalProps & {
  show: boolean;
  handleClose: () => void;
  handleConfirm?: () => void;
  children?: JSX.Element;
  confirmLabel?: string;
  hideCancelButton?: boolean;
  hideSaveButton?: boolean;
  height?: string;
  ajustes?: string;
  color?: string;
  borda?: string;
  circular?: string;
  changeFotter?: boolean;
};

export function Modal({
  show,
  handleClose,
  hideSaveButton,
  hideCancelButton,
  handleConfirm,
  confirmLabel,
  children,
  height,
  ajustes,
  borda,
  circular,
  color,
  changeFotter,
  ...rest
}: ModalProps) {
  return (
    <>
      <RBModal
        style={{ zIndex: "10000" }}
        show={show}
        onHide={handleClose}
        centered
        contentClassName="border-0"
        dialogClassName="modal-100w"
        {...rest}
      >
        <ModalFrame
          className={`${circular} border-${borda || 1} ${color}`}
          height={height}
        >
          {/* <RBModal.Header style={{border: 'none',}}>
            <RBModal.Title></RBModal.Title>
          </RBModal.Header> */}

          <RBModal.Body className={`border-0 ${ajustes}`}>
            {children}
          </RBModal.Body>

          {!changeFotter && (
            <RBModal.Footer className="border-0">
              {!hideCancelButton && (
                <ButtonCancel onClick={handleClose}>Cancelar</ButtonCancel>
              )}
              {!hideSaveButton && (
                <ButtonSave onClick={handleConfirm}>
                  {confirmLabel || "Salvar"}
                </ButtonSave>
              )}
            </RBModal.Footer>
          )}
        </ModalFrame>
      </RBModal>
    </>
  );
}

const ModalFrame = styled.div<{ height?: string }>`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: ${(props) => props.height};
  /* min-width: 200px;
  min-height: 100px; */
  /* background-color: transparent; */

  background-color: ${(props) => props.theme.colors.divBackground};

  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
`;

const ButtonCancel = styled.button`
  background: transparent;
  min-width: 126px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  border: none;
  border-radius: 6px;
  margin-right: 10px;
  ${(props) => ({ ...props.theme.fonts.primary.H5SemiBold })};
  color: ${(props) => props.theme.colors.iconsPrimary};
  transition: all 0.2s;

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.iconsPrimary} !important;
    background: transparent !important;
    color: ${(props) => props.theme.colors.iconsPrimary} !important;
  }

  &:active {
    background: transparent !important;
    border: none !important;
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.iconsPrimary};
  }
`;

const ButtonSave = styled.button`
  background: ${(props) => props.theme.colors.ibbxOrange};
  min-width: 126px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  border: none;
  border-radius: 6px;
  color: #fff !important;
  transition: all 0.2s;
  margin: 0 !important;
  ${(props) => ({ ...props.theme.fonts.primary.H5SemiBold })};

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.iconsPrimary} !important;
    background: ${(props) => props.theme.colors.ibbxOrange};
  }

  &:active {
    border: none !important;
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.iconsPrimary};
  }
`;
