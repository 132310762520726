import { MdError } from "react-icons/md";
import { useBatchActivation } from "./context";
import {
  Button,
  Title,
  BatchErrorsList,
  BatchErrorsItem,
  Spotlight,
  ErrorsAlert,
} from "./style";
import { IBactchActivatorErrors } from "./model";
import { useMemo } from "react";

export function BatchErrors() {
  const { closeModal, batchErrors } = useBatchActivation();
  const batchErrorsOrderByError = batchErrors.sort(
    (a: IBactchActivatorErrors, b: IBactchActivatorErrors) => {
      const aHasError = !!a.error;
      const bHasError = !!b.error;
      if (aHasError && !bHasError) return -1;
      if (!aHasError && bHasError) return 1;
      return 0;
    }
  );
  const devicesWithError: number = useMemo(() => {
    let total = 0;
    batchErrors.forEach((error) => {
      if (error.error) {
        total++;
      }
    });
    return total;
  }, [batchErrors]);

  return (
    <>
      <Title draggable="true">Ativação em massa de dispositivos</Title>
      <p>
        A ativação foi concluída,{" "}
        <Spotlight>porém alguns itens não foram ativados com sucesso</Spotlight>
        . Abaixo, você pode verificar os detalhes completos do processo:
      </p>

      <ErrorsAlert>
        <MdError size={20} />
        {devicesWithError || "null"} dispositivos falharam na ativação em massa
      </ErrorsAlert>
      <BatchErrorsList>
        {batchErrorsOrderByError &&
          batchErrors.map((error: any) => (
            <BatchErrorsItem isError={!!error.error}>
              <span>
                <strong>Ponto:</strong> {error.positionName}
              </span>
              <span>
                <strong>Descrição:</strong>{" "}
                {error.error || "ativado com sucesso!"}
              </span>
            </BatchErrorsItem>
          ))}
      </BatchErrorsList>
      <Button onClick={closeModal}>Fechar</Button>
    </>
  );
}
