import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    font-size: 0.875rem;
    padding: 0;
    margin: 0;
  }
`;

export const CloseButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.iconsSecondaryAlpha};
  background-color: transparent;
  padding: 0.25rem;
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.iconsSecondary};

  svg {
    font-size: 1.25rem;
  }

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.iconsSecondary};
    color: ${({ theme }) => theme.colors.iconsSecondary};

    background-color: ${({ theme }) => theme.colors.iconsSecondaryAlpha};
  }
  transition: all 0.3s ease;
`;

export const Title = styled.h4`
  font-size: 1rem;
  font-weight: bold;
  padding: 0;
  margin: 0;
`;

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "ibbxOrange";
  }
  if (props.isDragActive) {
    return "ibbxOrange";
  }
  if (props.isDragReject) {
    return "alarmRisco";
  }
  if (props.isFocused) {
    return "ibbxOrange";
  }
  return "cardHighlight";
};

export const DropZone = styled.div`
  cursor: pointer;
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px dotted ${(props) => props.theme.colors[getColor(props)]};
  border-radius: 10px;
  gap: 1rem;
`;

export const DropZoneInfo = styled.p`
  font-size: 0.85rem;
  width: 80%;
  text-align: center;
`;

export const GuideAlert = styled.span`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.ibbxOrange};
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.ibbxOrange};
  color: ${({ theme }) => theme.colors.iconsPrimary};
  padding: 0.59rem 1rem;
  border-radius: 6px;
  transition: all 0.3s ease;
  ${({ theme }) => theme.fonts.primary.H4Bold};

  :hover {
    border: 1px solid ${(props) => props.theme.colors.iconsSecondary};
  }

  :disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: none;
  }
`;

export const FileInfo = styled.span`
  color: ${({ theme }) => theme.colors.itemsHighlight};
`;

export const GuideLink = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.ibbxOrange};
  font-weight: bold;
  text-decoration: underline;
`;

export const ErrorList = styled.ul`
  font-size: 0.875rem;
  color: #ec5151;
  list-style: none;
  padding-left: 1rem;

  ul {
    list-style: none;
    padding-left: 0;
  }
`;

export const SucessMensage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BatchErrorsList = styled.ul`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  list-style: none;
  display: flex;
  padding: 0;
  padding-right: 0.5rem;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.875rem;

  &::-webkit-scrollbar {
    width: 7px !important;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background} !important;
    width: 20px !important;
    border-radius: 100vw !important;
    margin-block: -4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.iconsSecondaryAlpha} !important;
    height: 100% !important;
    max-height: 5rem !important;
    border-radius: 100vw !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.iconsSecondary} !important;
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${({ theme }) => theme.colors.iconsSecondary} !important;
  }
`;

export const BatchErrorsItem = styled.li<{ isError: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.cardHighlight};
  border-radius: 5px;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  position: relative;
  display: grid;
  grid-template-columns: 95px 1fr;
  gap: 1rem;

  > span {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &::before {
    content: "";
    border-radius: 5px 0 0 5px;
    background-color: ${({ isError, theme }) =>
      isError ? "#ec5151" : theme.colors.normalStatus};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    height: 100%;
    width: 5px;
    opacity: 0.8;
  }
`;

export const Spotlight = styled.strong`
  color: ${({ theme }) => theme.colors.ibbxOrange};
`;

export const ErrorsAlert = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  border-radius: 5px;
  border: 1px solid #ec5151;
  font-size: 0.875rem;
  padding: 0.5rem;

  > svg {
    color: #ec5151;
  }
`;
