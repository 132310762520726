import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

// AWS
import { Amplify, Auth, API } from "aws-amplify";
import { awsConfig } from "../awsConfig";
import { formatterLoginErrorMessage } from "../../utils/loginMessages";
import { error } from "../../components/Toast";

//Configuração para acesso ao Cognito e ao API Gateway
Amplify.configure(awsConfig);
const apiRetinaCustomer = awsConfig.API.endpoints[0].name;

//Modelo de dados do usuário
interface SliceState {
  loginMessage: string;
  loginNewPassword: boolean;
  loginTmpPassword: string;
  id: string;
  name: string;
  email: string;
  company: string;
  facility: string;
  profile: string;
  home: string;
}

//Valor inicial do usuário
const initialState: SliceState = {
  loginMessage: "",
  loginNewPassword: false,
  loginTmpPassword: "",
  id: "",
  name: "",
  email: "",
  company: "",
  facility: "",
  profile: "",
  home: "",
};

export const PROFILES_BITS = {
  EXECUTIVO_MASTER:
    "000000000000000000000000000100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000",
  MESA_MASTER:
    "000000000000000000000000000100000000000000000100100000000000000000000000000000000000000000000000000000100000000000010000100000000010000000000000000000",
  MESA_ANALISTA:
    "000000000000000000000000000000000000000001000100000000000000000000000000000000000000000000000000000000100000000000010000100000000010000000000000000000",
  CLIENTE_MASTER:
    "000000000000000000000000000000001000000000000100000000000000000000000000000000000000000000000000000010100000000000010000100000000010000000000000000000",
  CLIENTE_COMUN:
    "000000000000000000000000000000000010000000000100000000000000000000000000000000000000000000000000000010100000000000010000100000000010000000000000000000",
};

export const PROFILES = {
  EXECUTIVO_MASTER: "EXECUTIVO_MASTER",
  MESA_MASTER: "MESA_MASTER",
  MESA_ANALISTA: "MESA_ANALISTA",
  CLIENTE_MASTER: "CLIENTE_MASTER",
  CLIENTE_COMUN: "CLIENTE_COMUM",
  REPRESENTANTE: "REPRESENTANTE",
  CLIENTE_MASTER_VIEW: "CLIENTE_MASTER_VIEW",
};

// Retorna a homepage de acesso do usuário
// Mapeado pelos bits do profile
/*
REGRAS:

* Executivo Master
bit 27 = 1
Acessa todas companies
Começa no Épico 1

* Mesa Master
bit 27 = 1
Acessa todas companies
Começa no Épico 1

* Mesa Analista
bit 41 = 1
Acessa companies e facilities selecionadas
Começa no Épico 1

* Cliente Company Master
bit 30 = 1
Acessa companies selecionadas e todas suas facilities
Começa no Épico 1

* Cliente Master
bit 32 = 1
Acessa todas as facilities de sua company
Começa no Épico 2

* Cliente Comum
bit 33 = 1
Acessa todas apenas a sua facility
Começa no Épico 3

*/
const getHome = (profile: string) => {
  if (
    [
      PROFILES.EXECUTIVO_MASTER,
      PROFILES.MESA_ANALISTA,
      PROFILES.MESA_MASTER,
      PROFILES.MESA_ANALISTA,
    ].includes(profile)
  ) {
    return "companies"; //acesso a todas companies e facilities
  } else if ([PROFILES.CLIENTE_MASTER].includes(profile)) {
    return "facilities"; //acesso a todas facilities da mesma company
  } else {
    return "assets"; // acesso apenas à sua facility
  }
};

const getProfile = (profile: string) => {
  // @TODO update to get profile type by specific bits
  switch (profile) {
    case PROFILES_BITS.EXECUTIVO_MASTER:
      return PROFILES.EXECUTIVO_MASTER;
    case PROFILES_BITS.MESA_MASTER:
      return PROFILES.MESA_MASTER;
    case PROFILES_BITS.MESA_ANALISTA:
      return PROFILES.MESA_ANALISTA;
    case PROFILES_BITS.CLIENTE_MASTER:
      return PROFILES.CLIENTE_MASTER;
    default:
      return PROFILES.CLIENTE_COMUN;
  }
};

// VERIFICA SE HÁ TOKEN NO LOCAL STORAGE --------------------------------------------
export const userSession = createAsyncThunk("user/userSession", async () => {
  const session = await Auth.currentSession();

  const profile = getProfile(session.getIdToken().payload.profile);
  const resp: SliceState = {
    loginMessage: "",
    loginNewPassword: false,
    loginTmpPassword: "",
    id: session.getIdToken().payload.sub,
    name: session.getIdToken().payload.name,
    email: session.getIdToken().payload.email,
    company: session.getIdToken().payload.family_name,
    facility: session.getIdToken().payload.given_name,
    profile,
    home: getHome(profile),
  };

  return resp;
});

// LOGIN --------------------------------------------
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (params: { email: string; password: string }) => {
    const user = await Auth.signIn(params.email, params.password);

    if (
      user.hasOwnProperty("challengeName") &&
      user.challengeName === "NEW_PASSWORD_REQUIRED"
    ) {
      // Novo usuário, força a troca de senha
      const resp: SliceState = {
        loginMessage: "",
        loginNewPassword: true,
        loginTmpPassword: params.password,
        id: "",
        name: "",
        email: params.email,
        company: "",
        facility: "",
        profile: "",
        home: "",
      };
      return resp;
    } else {
      // Usuário já existe, faz o login
      const profile = getProfile(user.attributes.profile);
      const resp: SliceState = {
        loginMessage: "",
        loginNewPassword: false,
        loginTmpPassword: "",
        id: user.attributes.sub,
        name: user.attributes.name,
        email: user.attributes.email,
        company: user.attributes.family_name,
        facility: user.attributes.given_name,
        profile,
        home: getHome(profile),
      };

      return resp;
    }
  }
);

// FORÇA NOVA SENHA - USUÁRIO NOVO --------------------------------------------
export const userNewPassword = createAsyncThunk(
  "user/userNewPassword",
  async (params: {
    email: string;
    loginTmpPassword: string;
    newPassword: string;
  }) => {
    const user = await Auth.signIn(params.email, params.loginTmpPassword);
    const user2 = await Auth.completeNewPassword(user, params.newPassword);
    const profile = getProfile(user2.challengeParam.userAttributes.profile);
    const resp: SliceState = {
      loginMessage: "",
      loginNewPassword: false,
      loginTmpPassword: "",
      id: user2.username,
      name: user2.challengeParam.userAttributes.name,
      email: user2.challengeParam.userAttributes.email,
      company: user2.challengeParam.userAttributes.family_name,
      facility: user2.challengeParam.userAttributes.given_name,
      profile,
      home: getHome(profile),
    };
    return resp;
  }
);

// TROCA SENHA - USUÁRIO EXISTENTE --------------------------------------------
/***********  TERMINAR / TESTAR / CORRIGIR ***************************************/
export const userChangePassword = createAsyncThunk(
  "user/userChangePassword",
  async (params: { oldPassword: string; newPassword: string }) => {
    const authenticated = await Auth.currentAuthenticatedUser();
    if (!authenticated?.err) {
      return Auth.changePassword(
        authenticated,
        params.oldPassword,
        params.newPassword
      );
    } else {
      throw new Error(authenticated?.err);
    }
  }
);

// RETORNA OS TERMOS DO USUÁRIO: 0 - não aceitou os termos de uso; 1 - aceitou os termos de uso
export const userTerms = createAsyncThunk("user/userTerms", async () => {
  const authUser = await Auth.currentAuthenticatedUser();
  const userAttributes = await Auth.userAttributes(authUser);
  const zoneinfoAttribute = userAttributes.find(
    ({ Name }) => Name === "zoneinfo"
  );

  if (!zoneinfoAttribute) {
    return -1;
  }

  // Verify if user has accpet terms
  const { terms } = JSON.parse(zoneinfoAttribute.Value || "{}");

  return terms ? 1 : 0;
});

// REGISTRA TERMOS DO USUÁRIO: 0 - não aceitou os termos de uso; 1 - aceitou os termos de uso
export const userSetTerms = createAsyncThunk(
  "user/userSetTerms",
  async (terms: boolean) => {
    if (!terms) {
      return 0;
    }

    const session = await Auth.currentSession();
    const userId = session.getIdToken().payload.sub;

    const path = `/users/${userId}/accept-terms`;

    await new Promise((resolve, reject) => {
      API.put(apiRetinaCustomer, path, {})
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          // VERIFICAR NO BACKEND BUG DE USUÁRIO NOVO
          console.log(error.message);
          reject(error);
        });
    });

    return 1;
  }
);

// LOGOUT --------------------------------------------
export const userLogout = createAsyncThunk("user/userLogout", async () => {
  await Auth.signOut();
});

// Cria o slice de usuário
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userFn1: (state) => {
      state.id = "dummy";
    },
    userFn2: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // userSession ----------------------------------------------------------------------
      .addCase(userSession.fulfilled, (state, action) => {
        state.loginMessage = action.payload.loginMessage;
        state.loginNewPassword = action.payload.loginNewPassword;
        state.loginTmpPassword = action.payload.loginTmpPassword;
        state.id = action.payload.id;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.company = action.payload.company;
        state.facility = action.payload.facility;
        state.profile = action.payload.profile;
        state.home = getHome(action.payload.profile);
      })

      // userLogin ----------------------------------------------------------------------
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loginMessage = action.payload.loginMessage;
        state.loginNewPassword = action.payload.loginNewPassword;
        state.loginTmpPassword = action.payload.loginTmpPassword;
        state.id = action.payload.id;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.company = action.payload.company;
        state.facility = action.payload.facility;
        state.profile = action.payload.profile;
        state.home = action.payload.home;
      })
      .addCase(userLogin.rejected, (state, action) => {
        error(action.error?.message);
        console.log("Erro");
      })

      // userNewPassword ----------------------------------------------------------------------
      .addCase(userNewPassword.fulfilled, (state, action) => {
        state.loginMessage = action.payload.loginMessage;
        state.loginNewPassword = action.payload.loginNewPassword;
        state.loginTmpPassword = action.payload.loginTmpPassword;
        state.id = action.payload.id;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.company = action.payload.company;
        state.facility = action.payload.facility;
        state.profile = action.payload.profile;
        state.home = action.payload.home;
      })

      // userLogout ----------------------------------------------------------------------
      .addCase(userLogout.fulfilled, (state) => {
        state.loginMessage = "";
        state.id = "";
        state.name = "";
        state.email = "";
        state.company = "";
        state.facility = "";
        state.profile = "";
        state.home = "";
      });
  },
});

export const userSelect = (state: RootState) => state.user;

//export const { userFn1, userFn2 } = userSlice.actions;

export default userSlice.reducer;
