import styled, { css } from "styled-components";

const listStyles = css`
  width: 200px !important;
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H5Regular};

  text-decoration: none;

  display: flex;
  flex-direction: column;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: ${(props) => props.theme.colors.textPrimary};
  }

  & div {
    & > span:first-child {
      color: ${(props) => props.theme.colors.iconsSecondary};
    }
  }
`;

export const AnchorListContainer = styled.a`
  ${listStyles};
`;

export const ButtonListContainer = styled.button`
  ${listStyles};
`;

export const DivListContainer = styled.div`
  min-width: 200px;
  width: auto;
  ${listStyles};
`;

export const ListItem = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
`