import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import queryString from "query-string";

import { Modal } from "../../../../components/Modal";

import { ButtonCancel, Container, Footer, Title } from "./styles";
import { Button } from "../../../../components";
import useApi from "../../../../hooks/useApi";
import { useLocation } from "react-router-dom";

export const ExportCompaniesCSV = () => {
  const location = useLocation();

  const csvRef = useRef<any>(null);

  const { request } = useApi({
    path: "/web/companies",
  });

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState<String>("");
  const [data, setData] = useState<any>([]);
  const [completed, setCompleted] = useState(false);

  async function fetchPages() {
    setMessage("Buscando dados 0%...");
    setCompleted(false);

    const params = queryString.parse(location.search);

    const response = await request({
      method: "get",
      queryStringParameters: {
        ...params,
      },
    });

    setMessage(`Buscando dados...`);

    const formattedData = response.map((item: any) => {
      return {
        ID: item.companyId,
        EMPRESA: item.companyName,
        "Nº USUARIOS": item.countUsers,
        "Nº UNIDADES": item.countFacilities,
        "Nº ATIVOS": item.countAssets,
        "Nº PONTOS CADASTRADOS": item.countPositions,
        "Nº PONTOS ATIVADOS": item.countActivatedPositions,
        "Nº PONTOS CONECTADOS": item.countConnectedPositions,
      };
    });

    console.log(formattedData);

    setMessage("CSV pronto!");
    setCompleted(true);
    setData(formattedData);
  }

  useEffect(() => {
    if (show) {
      fetchPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const date = new Date();

  return (
    <>
      <Button onClick={() => setShow(true)}>Exportar CSV</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        confirmLabel="Baixar"
        size="lg"
        disabledConfirm={false}
        changeFotter
      >
        <Container>
          <Title>
            <strong>{`Exportando dados`}</strong>
          </Title>
          <p>{message}</p>
          {completed && (
            <CSVLink
              separator=";"
              style={{ display: "none" }}
              ref={csvRef}
              data={data}
              filename={`pontos-sem-dados-${date.getDay()}/${
                date.getMonth() + 1
              }.csv`}
              className=""
            ></CSVLink>
          )}
          <Footer>
            <div>
              <ButtonCancel onClick={() => setShow(false)}>
                Cancelar
              </ButtonCancel>
              <Button
                disabled={!completed}
                variant="primary"
                onClick={() => {
                  csvRef.current.link.click();
                }}
              >
                Baixar
              </Button>
            </div>
          </Footer>
        </Container>
      </Modal>
    </>
  );
};
