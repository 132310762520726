import { ReactElement } from "react";

export interface BatchActivationProps {
  onClose: () => void;
  companyId: number;
  children: ReactElement;
  burninSize: number;
  devicesQuantity: number;
}

export interface IavailableSlots {
  positionId: number;
  positionName: string;
  positioAvailable: boolean;
}

export interface IGuideColumns {
  activatorId: string;
  positionName: string;
}

export interface IBatchActivationContext {
  closeModal: () => void;
  setStep: any;
  burninSize: number;
  devicesQuantity: number;
  step: StepsBatchActivationEnum;
  companyId: number | undefined;
  batchErrors: IBactchActivatorErrors[];
  setBatchErrors: any;
}

export interface IBactchActivatorErrors {
  activatorId: string;
  positionName: string;
  positionId: number | null;
  created: boolean;
  ativated: boolean;
  error: string;
}

export enum StepsBatchActivationEnum {
  UPLOAD_FILE,
  SUCCESS,
  ERROS,
}
